import React, { useEffect, useRef } from 'react';
import Advantage from '../Component/advantage';
import Download from '../Component/download/download';
import Header from '../Component/header';
import Payment from '../Component/payment/payment';
import Promotion from '../Component/promotion/promotion';
import Salons from '../Component/salon';

// import Salons from '../Component/salon';
// import Filter from '../Component/salon/filter';
import Testmonials from '../Component/testimonials';
import Footer from '../Component/footer/footer';
import './dashbord.css';

export default function Dashboard() {
  const btnsRef = useRef();
  const slidesRef = useRef();
  const imgsRef = useRef();

  useEffect(() => {
    btnsRef.current = document.querySelectorAll(
      '.homepage .navigate .btns button'
    );
    slidesRef.current = document.querySelector('.homepage .slides');
    imgsRef.current = document.querySelectorAll('.homepage .slides div');
  });

  function slide() {
    slidesRef.current.style.transition = 'transform 0.5s';
    slidesRef.current.style.transform = `translateX(calc(-${img} * 100%))`;
  }

  // TODO:: fix this; put it in state!
  let img = 1;
  function btnClick(e) {
    if (e.target.id === 'left') {
      img--;
    } else {
      img++;
    }
    e.target.disabled = true;
    slide();
  }

  function handleTransition() {
    if (imgsRef.current[img].getAttribute('data-num') === 'first') {
      slidesRef.current.style.transition = 'none';
      img = 3;
      slidesRef.current.style.transform = `translateX(calc(-${img} * 100%))`;
    } else if (imgsRef.current[img].getAttribute('data-num') === 'last') {
      slidesRef.current.style.transition = 'none';
      img = 1;
      slidesRef.current.style.transform = `translateX(calc(-${img} * 100%))`;
    }
    btnsRef.current[0].disabled = false;
    btnsRef.current[1].disabled = false;
  }

  function headerScroll() {
    window.scrollBy({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
  return (
    <>
      <header className="homepage dark-bg" style={{marginBottom : 40}}>
        <Header textColor="white" />
        <div className="slider">
          <div className="slides" onTransitionEnd={handleTransition}>
            <div data-num="first"></div>
            <div></div>
            <div></div>
            <div></div>
            <div data-num="last"></div>
          </div>
        </div>
        <div className="homepage-hero">
          <div className="content-dash">
            <h1>Envie de prendre soin de vous !</h1>

            <h2>
              N’attendez plus… Sur Metime, vous pouvez réserver votre place dans 
              <br/>
              les meilleurs salons de beauté et vous pouvez également profiter
              de nos services à domicile.
            </h2>
            {/* <h2 id="mobile-subtitle">
              We make sure to offer you the best beauty services in your area,
              Click on the lik below to start reserving.
            </h2> */}
          </div>
          <div className="navigate" style={{ paddingLeft: 20 }}>
            <div>
              <div>
                <span>Défiler en bas</span>
                <img
                  src="./assets/ic_round-chevron-left.png"
                  alt="scroll"
                  id="scroll-arrow-desktop"
                />
                <img
                  src="./assets/down_scroll.png"
                  alt="scroll"
                  id="scroll-arrow-mobile"
                />
              </div>
              <img
                src="./assets/Group.png"
                alt="scroll"
                id="scroll-mouse-desktop"
                onClick={headerScroll}
              />
              <img
                src="./assets/a.png"
                alt="scroll"
                id="scroll-mouse-mobile"
                onClick={headerScroll}
              />
            </div>
            <div className="btns">
              <button onClick={btnClick} id="left">
                <img src="./assets/flesh on left.svg" alt="left" />
              </button>
              <button onClick={btnClick} id="right">
                <img src="./assets/flesh on right.svg" alt="right" />
              </button>
            </div>
          </div>
          <img
            className="mobile-hero-img"
            src="./assets/hero imgs.png"
            alt="mobile hero img"
          />
        </div>
        {/* only in mobile */}
        <img src="./assets/MeTime.png" alt="metime" id="metime" />
      </header>
      {/* <Filter /> */}
       <Salons /> 
      <Advantage />
      <Testmonials />
      <Download />
      <Payment />
      <Footer />
    </>
  );
}

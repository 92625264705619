import React from "react";

const description = (props) => {
  return (
    <section className="desc">
      <a href="#">
        <img src="./assets/Line 8.png" alt="" />
        <span>Back</span>
      </a>
      <div className="container">
        <img src="./assets/image.png" alt="" />
        <div>
          <h1>Jihane Beauty</h1>
          <h3>hairdresser, Hammam & Spa, Aesthetic, Tattoo & Piercing</h3>
          <div className="rating">
            <img src="./assets/Star 15.png" alt="" />
            <img src="./assets/Star 15.png" alt="" />
            <img src="./assets/Star 15.png" alt="" />
            <img src="./assets/Star 15.png" alt="" />
            <img src="./assets/Star 15.png" alt="" />
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit semper
            est arcu enim. Ornare semper pharetra elit tempor, pharetra massa,
            amet, facilisi sed. Consequat gravida pulvinar ornare phasellus sit
            malesuada congue bibendum luctus. Sapien venenatis semper sed
            interdum etiam nisl, ac nulla. Eget duis dictum sit est id duis
            sagittis pellentesque. Nibh congue dolor est, leo, ut magna. Lorem
            dignissim id est quis facilisis tellus, amet blandit egestas.
            Interdum posuere lacus sagittis morbi amet non pharetra. Tortor eu
            hendrerit magna odio in eu. Eu viverra ac consequat aliquam luctus
            at. Magna nisi faucibus porttitor ullamcorper pretium. Pharetra
            mauris, enim nulla ac morbi. Ut metus turpis est a ornare
            vestibulum, pulvinar id. Ipsum ut eget dui neque, blandit in.
            Senectus amet rhoncus, lacus ultrices. Diam volutpat, vulputate amet
            at dui quisque fringilla fringilla donec. Nec elementum blandit quis
            tellus urna, in arcu. Ridiculus sit odio pretium sit sit sodales leo
            aliquet donec. Duis purus dolor id amet turpis urna nibh. Non
            viverra quis nisi sollicitudin elit. Tristique ac amet, ullamcorper
            tincidunt tempor, pharetra in faucibus nec. Tincidunt ultricies
            blandit felis cras pretium egestas ac lorem. Morbi nec.
          </p>
        </div>
      </div>
    </section>
  );
};

export default description;

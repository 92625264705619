import React from 'react';
import { Link } from 'react-router-dom';
import './promotion.css';

const promotion = (props) => {
  return (
    <section >
      <Link to="/signup">
        <div className="container">
          <img
            className="promoDes"
            src={props.homepage ? 'assets/webpromo.png' : 'assets/webpromo.png'}
            alt=""
          />
          <img
            className="promoMb"
            src={
              props.homepage ? 'assets/phonepromo.png' : 'assets/phonepromo.png'
            }
            alt=""
          />
        </div>
      </Link>
    </section>
  );
};

export default promotion;

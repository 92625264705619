import React, { useCallback, useState, useEffect } from 'react';
import Header from '../Component/header';
import Footer from '../Component/footer/footer';
import Salon from '../Component/salon/salon';
import { Link } from 'react-router-dom';
import * as api from '../utils/api';
import useApi from '../hooks/useApi';
import useQuery from '../hooks/useQuery';
import { useHistory, useParams } from 'react-router-dom';
import './sallon_map.css'
import Map_s from './Map_s';

const PAGE_SIZE = 9;

function isInteger(value) {
  const regex = /^[0-9]{1,11}$/;

  return Boolean(value && regex.test(value));
}

export default  function SearchArea() {
  const history = useHistory();
  const query = useQuery();
  const { id: cityId } = useParams();
  const [salons, setSalons] = useState([]);
  const [count, setCount] = useState(0);

  const [lat, setlat] = useState(null);
  const [lng, setlng] = useState(null);
  const [checkboxs, setCheckboxs] = useState({
    MALE: false,
    FEMALE: false,
    MIXED: false,
  });
  const [city , setCity] = useState({})
  const filterHandler = async (e) => {
    e.preventDefault();
    const keys = Object.keys(checkboxs);
    if (checkboxs[keys[0]]) {
      const updatedSalon = salons.filter((s) => s.roomCategory === keys[0]);
      await setSalons(updatedSalon);
    }
  };
  const getCitySalons = async (page) => {
    const resp = await api.getRooms(cityId, page, PAGE_SIZE);
    
    

    const keys = Object.keys(checkboxs);
    if (checkboxs[keys[0]]) {
      const updatedSalon = resp.data.data.filter((s) => s.roomCategory === keys[0]);
     return  await setSalons(updatedSalon);
    }

   return await setSalons(resp.data.data);

  }
  useEffect( async ()=> {
    const page = getPage(query.get('page'));
    const resp = await api.getRooms(cityId, page, PAGE_SIZE);
    
    await setSalons(resp.data.data);
    await setCount(resp.data.count);

    await setCity(resp.data.city);
  setlat(resp.data.data[0].latitude);
  setlng(resp.data.data[0].longitude);
  }, [])
  

  function nextPage() {
    const page = getPage(query.get('page'));

    const pageCount = Math.ceil(count / PAGE_SIZE);

    if (page < pageCount) {
      getCitySalons(page + 1)
      history.push({
        pathname: window.location.pathname,
        search: `?page=${page + 1}`,
      });
    }
  }

  function prevPage() {
    const page = getPage(query.get('page'));

    if (page > 1) {
      getCitySalons(page - 1)
      history.push({
        pathname:  window.location.pathname,
        search: `?page=${page - 1}`,
      });
    }
  }

  function getPage(page) {
    if (isInteger(page)) {
      return parseInt(page, 10);
    } else {
      return 1;
    }
  }

  const currentPage = getPage(query.get('page'));

  return (
    <div>
      <header className="search white-bg">
        <Header />
      </header>
      <section className="salons search-area">
        <Link to="/startbooking" className="back-btn">
          <img src="./assets/Line 8.png" alt="" />
          <span>Retour</span>
        </Link>
        <h2>Les Meilleurs Salons autour de vous </h2>
        <h4>
          <img src="./assets/location.png" alt="" />
           <span>{city.label}</span> 
        </h4>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {Boolean( salons.length == 0) ? (
            <div
              style={{
                margin: 'auto',
              }}
            >
              <img src="./assets/amico.png" alt="" />
            </div>
          ) : null}
          <div className="form" style={{ padding: 25, marginBottom: 30 }}>
            <h4>Filter par :</h4>
            <form className="f-inp">
              <div>
                <h4>Catégories :</h4>
                <div className="checkboxs">
                  <label htmlFor="Hammam">
                    <input
                      type="checkbox"
                      className="inp"
                      checked={checkboxs.MALE}
                      onChange={(e) => setCheckboxs({ MALE: !checkboxs.MALE })}
                    />
                    Hommes
                  </label>
                  <label htmlFor="Coiffure">
                    <input
                      type="checkbox"
                      id="Hairdresser"
                      checked={checkboxs.FEMALE}
                      onChange={(e) =>
                        setCheckboxs({
                          FEMALE: !checkboxs.FEMALE,
                        })
                      }
                    />
                    Femmes
                  </label>
                  <label htmlFor="Esthétique">
                    <input
                      type="checkbox"
                      checked={checkboxs.MIXED}
                      onChange={(e) =>
                        setCheckboxs({
                          MIXED: !checkboxs.MIXED,
                        })
                      }
                    />
                    Mixtes
                  </label>
                </div>
              </div>
              <input onClick={filterHandler} type="submit" value="Filter" />
            </form>
          </div>

          <div className="search-area-container search-sln">
            { salons.map(function renderSalon(room) {
                  const imageUrl = `${process.env.REACT_APP_API_URL}/rooms/images/${room.image}`;

                  return (
                    <Salon
                      key={room.id}
                      id={room.id}
                      name={room.name}
                      address={room.address}
                      image={imageUrl}
                      show={true}
                    />
                  );
                })
             }
          </div>
        </div>

        { (
          <div className="pagination">
            <img src="./assets/goleft.png" alt="" onClick={prevPage} />
            <h4>
              <span>{currentPage}</span> sur
              <span>{Math.ceil(count / PAGE_SIZE)}</span>
            </h4>
            <img src="./assets/goright.png" alt="" onClick={nextPage} />
          </div>
        ) }
      </section>
	  <div id="map_sallon" >
		{(lat!=null && lng!=null)&&<Map_s lat={lat} lng={lng}/>}
		</div>
      <Footer />
    </div>
  );
}

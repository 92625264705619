import React, { useEffect } from "react";
import HeaderAuth from "../Component/headerAuth";
import "./login.css";
import "./Contactus.css";
import Footer from '../Component/footer/footer';

export default function Contactus() {

  useEffect(()=> {

    window.scrollTo(0, 0);
  } , [])
  return (
    <>
      <HeaderAuth showMainLinksSection={false} />

      <div className="contact-container">
        <div className="left">
          <form className="contact-info" method="POST" action="/url">
            <h1>Contactez Nous</h1>
            <p>
            Besoin de nous contacter? remplissez le formulaire avec votre demande ou trouvez ci-dessous l'adresse e-mail du service que vous souhaitez contacter.


            </p>
            <div className="form-group">
              <input name="name" type="text" placeholder="Nom Complet" />
              <input name="email" type="email" placeholder="Email" />
              <input name="phone" type="text" placeholder="Numero De Telephone" />
              <input name="request" type="text" placeholder="Votre Demande" />
            </div>
            <button>Envoyer</button>
          </form>
        </div>
        <div className="right">
          <h1>
          Que pouvons-nous <br /> faire pour <br /> vous aider ?            
          </h1>
        </div>
      </div>
<Footer/>
    </>
  );
}

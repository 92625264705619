import { useFormik } from "formik";
import * as yup from "yup";
import * as api from "../utils/api";

const validationSchema = yup.object({
  email: yup.string().email().required().default(""),
  token: yup.string().required().default(""),
  newPassword: yup.string().min(8).required().default(""),
  confirmPassword: yup
    .string()
    .min(8)
    .required()
    .default("")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export default function usePasswordStepForm(props) {
  const { initialValues, onSuccess, onError } = props;

  const formik = useFormik({
    initialValues: { ...validationSchema.getDefault(), ...initialValues },
    validationSchema: validationSchema,
    async onSubmit(values) {
      try {
        await api.resetPassword(values.email, values.token, values.newPassword);

        if (typeof onSuccess == "function") {
          onSuccess(values);
        }
      } catch (error) {
        if (typeof onError == "function") {
          onError(error);
        }
      }
    },
  });

  return { formik };
}

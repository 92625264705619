import React, { useState } from "react";
import Header from "../Component/header";
import Description from "../Component/salon/description";
import Footer from "../Component/footer/footer";
import ServiceReview from "../Component/ServiceReview/ServiceReview";
import Service from "../Component/Service";
import { Link } from "react-router-dom";
import ServiceCardItem from "../Component/ServiceCardItem";

export default function ServiceChosen() {
  const [reviews, setReviews] = useState([[], [], []]);
  const [items, setItems] = useState(null);
  const displayDrop = (e) => {
    e.target.nextElementSibling.classList.toggle("display");
  };

  return (
    <div>
      <header className="search white-bg">
        <Header />
      </header>
      <Description />

      <section className="services service-chosen">
        <h2>Our services</h2>
        <div className="container">
          <div className="services-dropdown">
            <div onClick={displayDrop}>
              <span>Hammam & Spa</span>
              <img src="./assets/down_scroll.png" alt="" />
            </div>
            <div className="services-hide">
              <span>Tattoo & Piercing</span>
              <span>Hairdresser</span>
              <span>Aesthetic</span>
            </div>
          </div>
          <div className="navigation">
            <a href="">Hammam & Spa</a>
            <a href="">Tattoo & Piercing</a>
            <a href="">Hairdresser</a>
            <a href="">Aesthetic</a>
          </div>
          <div className="middle">
            <Service />
            <Service />
            <Service />
            <Service />
          </div>
          <div className="note">
            <span>You chose: </span>
            {items ? (
              <>
                <div className="service-card">
                  <ServiceCardItem />
                </div>
                <button>Reserve now!</button>
              </>
            ) : (
              <div className="empty">
                <img src="./assets/bro.png" alt="empty card" />
                <p>You still didn’t choose any service</p>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d212090.99085434663!2d-5.558723543310546!3d33.8401290233624!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sma!4v1639155340370!5m2!1sen!2sma"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <section className="reviews">
        <h2>What our clients say?</h2>
        {reviews.map((item, index) => (
          <ServiceReview key={index} />
        ))}
      </section>
      <button className="reserve-service">
        Reserve <span>1</span> service | <span>100 dh</span>
      </button>
      <Footer />
    </div>
  );
}

import React, { useState } from "react";
import Header from "../Component/header";
import Footer from "../Component/footer/footer";
import { useAuth } from "../hooks/useAuth";
import { updateUser } from "../utils/api";
import { openNotificationWithIcon } from "../utils/apiService";

export default function Account() {
  const { user } = useAuth();

  const [edit, setEdit] = useState(false);
  const [values, setValues] = useState({
    firstName: `${user.firstName}`,
    lastName : ` ${user.lastName}` ,
    email: user.email,
    phoneNumber: user.phoneNumber,
  });
  const [passwords , setPasswords] = useState(null)
  const submitEdit = () => {
    let updateValues = values
    if(passwords)  {
      if(passwords.password === passwords.passwordConfirmation) {
        updateValues["password"] = passwords.password 
        updateValues["oldPassword"] = passwords.oldPassword 
      }
      else {
      return openNotificationWithIcon("warning" , "Error" , "YOUR PASSWORDS ARE NOT MATCHING")
      }
    }
  updateUser({
...updateValues  }).then(resp => {
  if(resp.status === 200) {
    openNotificationWithIcon("success" , "réussie" , "Votre compte a été bien modifié")
  }
 
}).catch(err => {
  if(err.response.data.statusCode === 422) {
    return openNotificationWithIcon("warning" , "Error" , err.response.data.details)
} 


  })

  }

  return (
    <div>
      <header className="search white-bg">
        <Header />
      </header>
      <section className="my-account">
        <div className="profile">
          <img style={{width : 100 , height : 100 , borderRadius : 50}} src="./assets/profil.jpg" alt="" />
          
        </div>
        <div className="account-details">
          <h3>Mon profil</h3>
          <div className="container">
            <div className="wrapper">
              <h5>Mon profil</h5>
              <div className="form-group">
                <label>Prénom:</label>
                <input
                  type="text"
                  placeholder={values.firstName}
                  onChange={(e) =>
                    setValues({ ...values, firstName: e.target.value })
                  }
                  disabled={!edit}
                />
              </div>
              <div className="form-group">
                <label>Nom:</label>
                <input
                  type="text"
                  placeholder={values.lastName}
                  onChange={(e) =>
                    setValues({ ...values, lastName: e.target.value })
                  }
                  disabled={!edit}
                />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="text"
                  placeholder={values.email}
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  disabled={!edit}
                />
              </div>
              
              <div className="form-group">
                <label>Téléphone:</label>
                <input
                  type="text"
                  placeholder={values.phoneNumber}
                  onChange={(e) =>
                    setValues({ ...values, phoneNumber: e.target.value })
                  }
                  disabled={!edit}
                />
              </div>
            </div>

            <div className="wrapper">
              <h5>Sécurite</h5>
              <div className="form-group">
                <label></label>
                <input  onChange={(e) =>
                    setPasswords({ ...passwords, oldPassword: e.target.value })
                  } type="password" placeholder={"Ancien Mot De Passe"} disabled={!edit} />
              </div>
              <div className="form-group">
                <label></label>
                <input onChange={(e) =>
                    setPasswords({ ...passwords, password: e.target.value })
                  }  type="password"placeholder={"Nouveau Mot De Passe"} disabled={!edit} />
              </div>
              <div className="form-group">
                <label></label>
                <input  onChange={(e) =>
                    setPasswords({ ...passwords, passwordConfirmation: e.target.value })
                  }type="password" placeholder={"Confirmer Mot De Passe"} disabled={!edit} />
              </div>
            </div>
          </div>
          <div className="btns">
            {edit ? (
              <div>
                <button onClick={() => submitEdit()}>Enregistrer</button>
                <button onClick={() => setEdit(!edit)}>Annuler</button>
              </div>
            ) : (
              <button onClick={() => setEdit(!edit)}>
                <img style={{width : 19 , height : 19}} src="./assets/pen.png" alt="pen icon" />
                <span>Modifier mon profil</span>
              </button>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}



import React, { useCallback } from 'react';
import Header from '../Component/header';
import Footer from '../Component/footer/footer';
import PendingReservation from '../Component/PendingReservation';
import useApi from '../hooks/useApi';
import * as api from '../utils/api';
import CompletedReservation from '../Component/CompletedReservation';

export default function Reservations() {
  const fetcher = useCallback(async function fetcher() {
    const { data } = await api.getReservations();
    const pending = [];
    const completed = [];

    data.forEach(function addReservation(reservation) {
      if (reservation.isCompleted) {
        completed.push(formatReservation(reservation));
      } else {
        pending.push(formatReservation(reservation));
      }
    });

    return { pending, completed };

    function formatReservation(reservation) {
      console.log(reservation.room.latitude)
      return {
        id: reservation.id,
        address: reservation.room.address,
        startDate: reservation.startDate,
        duration: getDuration(reservation.startDate, reservation.endDate),
        total: reservation.price,
        latitude: reservation.room.latitude , 
        longitude :  reservation.room.longitude 
      };
    }
  }, []);

  function getDuration(startDate, endDate) {
    return (
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / 60000
    );
  }

  const { isLoading, data: reservations /*, error */ } = useApi({ fetcher });

  return (
    <div>
      <header className="search white-bg">
        <Header />
      </header>
      <section className="my-reservation">
        <h2>Mes Résérvations</h2>
        <div className="container">
          <span>En Cours</span>
          {/* <h3>Jihan Beauty</h3> */}
          <div className="order">
            {!isLoading
              ? reservations.pending.map(function renderReservation(
                  reservation
                ) {
                  return (
                    <PendingReservation
                      key={reservation.id}
                      id={reservation.id}
                      address={reservation.address}
                      startDate={reservation.startDate}
                      duration={reservation.duration}
                      total={reservation.total} 
                      latitude  = {reservation.latitude} 
                      longitude={reservation.longitude} 

                    />
                  );
                })
              : null}
          </div>
        </div>
        <div className="container completed">
          <span>Historique de Réservations</span>
          {/* <h3>Emmy & Ann</h3> */}
          <div className="order">
            {!isLoading
              ? reservations.completed.map(function renderReservation(
                  reservation
                ) {
                  return (
                    <CompletedReservation
                      key={reservation.id}
                      id={reservation.id}
                      address={reservation.address}
                      startDate={reservation.startDate}
                      duration={reservation.duration}
                      total={reservation.total}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

import React from "react";

export default function Service() {
  const displayCard = (e) => {
    e.target.parentElement.parentElement.nextElementSibling.classList.toggle(
      "display-mobile"
    );
  };
  return (
    <div className="img">
      <div>
        <img src="./assets/unsplash_ZbzYDboN7fg.png" alt="service" />
        <div className="content">
          <div className="right">
            <h3>Chinese massage</h3>
            <div>
              <span>
                <img src="./assets/ic_baseline-access-time.png" alt="" />
                22 min
              </span>
              <span>
                <img src="./assets/ic_baseline-money.png" alt="" />
                100 dh
              </span>
            </div>
          </div>
          <img
            src="./assets/Vector.png"
            alt="add"
            className="plus chose"
            onClick={displayCard}
          />
        </div>
      </div>

      <div className="service-card mobile">
        <p>
          <span>Chinese massage</span>
          <img src="./assets/pill.png" alt="" />
        </p>
        <div>
          <h6>
            <img src="./assets/ic_baseline-access-time.png" alt="" />
            <span>22 min</span>
          </h6>
          <h6>
            <img src="./assets/ic_baseline-money.png" alt="" />
            <span>100 dh</span>
            <span>90 dh</span>
          </h6>
        </div>
        <img src="./assets/buttons.png" alt="" />
      </div>
    </div>
  );
}

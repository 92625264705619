import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(function appendAuthToken(config) {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

export function login(email, password) {
  return axiosInstance.post("/auth/login", { email, password });
}

export function verifyAccount(email, token) {
  return axiosInstance.get("/auth/activate", {
    params: {
      email,
      token,
    },
  });
}

export function resendVerificationToken(email) {
  return axiosInstance.post("/auth/resend-email", {
    email,
  });
}

export function sendResetPassword(email) {
  return axiosInstance.get("/auth/reset-password", {
    params: { email },
  });
}

export function resetPassword(email, token, password) {
  return axiosInstance.post("/auth/reset-password", {
    email,
    token,
    password,
  });
}

export function getUserProfile() {
  return axiosInstance.get("/users/profile");
}

export function getServices(roomId, category) {
  return axiosInstance.get(`/rooms/${roomId}/services`, {
    params: {
      category,
    },
  });
}

export function getRooms(cityId, page, limit) {
  return axiosInstance.get(`/cities/${cityId}/rooms`, {
    params: {
      page,
      limit,
    },
  });
}

export function addReservations(roomId, body) {
  return axiosInstance.post(`rooms/${roomId}/reservations`, body);
}

export function getReservations() {
  return axiosInstance.get("/reservations");
}

export function getCities(roomsCount = false) {
  return axiosInstance.get("/cities", {
    params: {
      rooms_count: roomsCount,
    },
  });
}

export function getCityById(id) {
  return axiosInstance.get(`/cities/${id}`);
}

export function updateUser(data) {
  return axiosInstance.patch(`/users/profile/`, data);
}

export function getAvailabilityStatus(roomId, startDate, servicesIds) {
  return axiosInstance.post(`/rooms/${roomId}/reservations/availability`, {
    startDate,
    servicesIds,
  });
}

export function getNearbyRooms(lat, lng, distance) {
  return axiosInstance.get("/rooms/filter", {
    params: {
      lat,
      lng,
      distance,
    },
  });
}

export function googleAuth(params) {
  return axiosInstance.get("/auth/google/redirect", {
    params,
  });
}

export function facebookAuth(params) {
  return axiosInstance.get("/auth/facebook/redirect", {
    params,
  });
}

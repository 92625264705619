import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useCart } from "react-use-cart";
import Header from "../Component/header";
import Footer from "../Component/footer/footer";
import ServiceCardItem from "../Component/ServiceCardItem";
import useReservationForm from "../hooks/useReservationForm";
import { hours } from "../utils/hours";
import * as api from "../utils/api";

function useAvailabilityStatus(roomId, date, time, services) {
  const [state, setState] = useState({
    isLoading: false,
    isAvailable: undefined,
    error: undefined
  });

  useEffect(() => {
    if (!date || !time) {
      setState({
        isLoading: false,
        isAvailable: undefined,
        error: undefined
      });

      return;
    }

    setState({
      isLoading: true,
      isAvailable: undefined,
      error: undefined
    })

    const startDate = `${date}T${time}Z`;
    const servicesIds = services.map((service) => service.id);

    api
      .getAvailabilityStatus(roomId, startDate, servicesIds)
      .then(({ data }) => setState({
        isLoading: false,
        isAvailable: data.isAvailable,
        error: undefined
      }))
      .catch((error) => setState({
        isLoading: false,
        isAvailable: undefined,
        error: error
      }));
  }, [date, time, services, roomId]);

  return state;
}

function getAvailabilityStatusText(availabilityStatus) {
  if (
    availabilityStatus.isLoading ||
    typeof availabilityStatus.isAvailable == "undefined"
  ) {
    return "-----";
  }

  if (!availabilityStatus.isAvailable) {
    return "date indisponible";
  }

  return "date disponible";
}

export default function BookingPage() {
  const { metadata, items, removeItem, emptyCart } = useCart();
  const [startDate] = useState( () =>{
    var today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    if(parseInt(today.getMonth())+1 < 10) {
     return today.getFullYear() +'-0'+(today.getMonth()+1)+'-'+today.getDate();
    }
    return date
  }
   );

   const [endDate] = useState( () =>{
    var today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+(today.getDate() + 7);
    if(parseInt(today.getMonth())+1 < 10) {
     return today.getFullYear() +'-0'+(today.getMonth()+1)+'-'+(today.getDate() + 7);
    }
    return date
  }
   );


  const history = useHistory();
  const { formik } = useReservationForm({
    initialValues: {
      roomId: metadata.roomId,
      servicesIds: items.map((service) => service.id),
    },
    onSuccess() {
      emptyCart();
      history.push("/reservations");
    },
  });

  const availabilityStatus = useAvailabilityStatus(
    metadata.roomId,
    formik.values.date,
    formik.values.time,
    items
  );

  return (
    <div>
      <header className="search white-bg">
        <Header />
      </header>
      <section className="booking-page">
        <button onClick={() => history.goBack()}>
          <img src="./assets/Line 8.png" alt="" />
          <span>Retour</span>
        </button>
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className="date">
              
              <h5>Choisir Une Date:</h5>

              <div className="wrapper">

                <input
                  type="date"
                  name="date"
                min={startDate} 
                max={endDate}
                  value={formik.values.date}
                  onChange={
                   formik.handleChange  
                  }
                />
               {/* <h5>
                  <span>Salon:</span>
                  <span>Jihan Beauty</span>
               </h5> */}
                <select name="time" onChange={formik.handleChange}>
                <option value="">-----</option>

                  {hours.map(h => <option value={h} key={h}>{h}</option>)}
                 
                </select>
                <p>
                  <span>Disponibilité:</span>
                  <span>{getAvailabilityStatusText(availabilityStatus)}</span>
                </p>
              </div>
            </div>
            <div className="card service-chosen">
              <div className="note">
                <span>Vous avez choisi :</span>
                <div className="service-card">
                  {items.map(function createCartItem(service) {
                    const { id, title, price, duration } = service;

                    return (
                      <ServiceCardItem
                        key={id}
                        id={id}
                        title={title}
                        price={price}
                        duration={duration}
                        onRemoveClick={removeItem}
                      />
                    );
                  })}
                </div>
                <div className="btns">
                  <button onClick={()=>history.goBack()}>
                    <div to="/services">Ajouter un autre service </div>
                  </button>
                  <button disabled={availabilityStatus.isLoading || !availabilityStatus.isAvailable} type="submit">Reservez Maintenant</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
  { /*   <section className="payment-method">
        <h2>Payment method</h2>
        <p>
          Select a payment method below. MeTime processes your payment securely
          with end-to-end encryption.
        </p>
        <div>
          <div>
            <img src="./assets/credit card.png" alt="credit card" />
            <span>Credit card</span>
          </div>
          <div>
            <img src="./assets/duplicate.png" alt="" />
            <span>Cash</span>
          </div>
        </div>
        <h4>Credit card details</h4>
        <form>
          <label htmlFor="card">
            <input type="checkbox" name="card" id="card" />
            Billing address is the same
          </label>
          <input
            type="text"
            name=""
            placeholder="Name of the card holder"
            id=""
          />
          <input type="text" placeholder="Card number" name="" id="" />
          <div className="ccv">
            <div>
              <input type="text" placeholder="MM/YY" />
              <span>MM/YY</span>
            </div>
            <div>
              <input type="text" maxLength="3" placeholder="CCV" />
              <img
                src="./assets/information.png"
                alt=""
                title="some infos here"
              />
            </div>
          </div>
          <h3>Payment method</h3>
          <img src="./assets/credit carda.png" alt="credit card" />
          <div className="service-card">
            <h4>Reserve</h4>
            <ServiceCardItem />
            <div className="btns">
              <button>Add another service</button>
              <button>Reserve now!</button>
            </div>
          </div>

          <h4>Share your booking itinerary</h4>
          <p>
            You can email your itinerary to anyone by entering their email
            address here.
          </p>
          <input type="email" placeholder="Email address" id="" />
          <input type="email" placeholder="Email address" id="" />
          <input type="email" placeholder="Email address" id="" />
          <div className="form-btns">
            <button>Email itinerary</button>
            <button>Add another</button>
          </div>
        </form>
      </section>
                */ }  <Footer />
    </div>
  );
}

/*global google*/
import React, { Component } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  DirectionsRenderer
} from "react-google-maps";
import { useParams } from "react-router";

import useGeolocation from "../hooks/useGeoLocation";

class Map extends Component {
  state = {
    directions: null , 
    lat : null , 
    lang : null
  };


   getPosition() {
    // Simple wrapper
    return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
    });
}

  async componentDidMount() {
   await this.getPosition().then(resp => this.setState({


        lat : resp.coords.latitude , 
        lang : resp.coords.longitude
    }) )

     const targetLocation = JSON.parse('{"' + decodeURI(this.props.location).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    if(!targetLocation.latitude || !targetLocation.longitude) { 
      window.location.href = "/"
    }
    
      const directionsService = new google.maps.DirectionsService();
     
    const origin = { lat: this.state.lat, lng:this.state.lang };
    const destination = { lat:  parseFloat(targetLocation.latitude), lng:  parseFloat(targetLocation.longitude) };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
        defaultZoom={13}
      >
        <DirectionsRenderer
          directions={this.state.directions}
        />
      </GoogleMap>
    ));

    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={{ height: `1000px`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%` , position : "unset"}} 
          
          />
        
        }
        />
      </div>
    );
  }
}



const MapCon = (props) => {
    const MapLoader = withScriptjs(Map);
    if(!props.match.params.location) 
      window.location.href = "/"
    return (
      <MapLoader  location = {props.match.params.location} 
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
        loadingElement={<div  />}
      />
    );
  };
  
export default MapCon;

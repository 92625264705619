import React from "react";

export default function ServiceCardItem(props) {
  const { id, title, price, duration, onRemoveClick } = props;

  function onRemoveClickHandler() {
    if (typeof onRemoveClick == "function") {
      onRemoveClick(id);
    }
  }

  return (
    <div className="service-card">
      <div className="item">
        <p>
          <span>{title}</span>
        </p>
        <div>
          <h6>
            <img src="./assets/ic_baseline-access-times.png" alt="" />
            <span>{duration} min</span>
          </h6>
          <h6>
            <img src="./assets/ic_baseline-moneys.png" alt="" />
            <span>{price} dh</span>
            <span>{price} dh</span>
          </h6>
        </div>
        <img src="./assets/buttons.png" alt="" onClick={onRemoveClickHandler} />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useLoginForm from "../hooks/useLoginForm";
import useBoolean from "../hooks/useBoolean";
import "./login.css";
import HeaderAuth from "../Component/headerAuth";

export default function Login() {
  const { formik } = useLoginForm();
  const { value: showPassword, toggle: toggleShowPassword } = useBoolean(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <header
      style={{
        overflow: window.innerWidth > 800 ? "hidden" : "auto",
      }}
    >
      <HeaderAuth showMainLinksSection={false} />
      <div className="bg"></div>
      <div className="hero">
        <div className="signin">
          <h1 className="form-title">Connexion</h1>
          <p className="form-sub-title">
            Vous n'avez pas encore de compte?{" "}
            <Link to="/signup">S'inscrire</Link>
          </p>
          <form onSubmit={formik.handleSubmit} className="hero-form">
            <div>
              <input
                type="email"
                autoFocus
                placeholder="Adresse email"
                required
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Votre Mot De Passe"
                required
                name="password"
                style={{ border: "none" }}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <img
                src="./assets/eye.png"
                alt="toggle password"
                onClick={toggleShowPassword}
              />
            </div>

            <input
              type="submit"
              style={{ color: "#fff", letterSpacing: "1.5px" }}
              value="Connexion"
            />

            <label
              htmlFor="remember"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "5px",
                cursor: "pointer",
                width: "max-content",
              }}
            >
              <input type="checkbox" id="remember" />
              Se Souvenir De Moi
            </label>
            <Link to="/reset" className="forgot-resend">
              Mot de passe oublié?
            </Link>
          </form>
          <div className="app-horizontal-devider">or</div>
          <div>
            <a
              href={`${process.env.REACT_APP_API_URL}/auth/google`}
              className="app-auth-link-container app-auth-link-container-google"
            >
              <span className="app-auth-link-icon app-auth-link-icon-google"></span>
              <span className="app-auth-link-text app-color-black">Google</span>
            </a>
          </div>

          <div>
            <a
              href={`${process.env.REACT_APP_API_URL}/auth/facebook`}
              className="app-auth-link-container app-auth-link-container-facebook"
            >
              <span className="app-auth-link-icon app-auth-link-icon-facebook"></span>
              <span className="app-auth-link-text app-color-white">
                Facebook
              </span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

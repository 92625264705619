import React from "react";
import Header from "../Component/header";
import Footer from "../Component/footer/footer";
import { Link } from "react-router-dom";
export default function ThankyouPage() {
  return (
    <div>
      <header className="search white-bg ">
        <Header />
      </header>
      <section className="thankyou_page">
        <h2>Jihane Beauty</h2>
        <div className="qr">
          <img
            src="./assets/QR_code_for_mobile_English_Wikipedia 1.png"
            alt=""
          />
          <h3>Thank you for trusting MeTime</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Eu eu diam
            turpis molestie eget fringilla.Ut id arcu enim vitae nisi, habitasse
            hac.Urna ullamcorper molestie pretium donec ac lorem pretium
            feugiat.Condimentum fermentum, in vel suscipit.Aliquet nulla duis
            vitae cras risus enim tempor proin mattis.Ac duis in posuere.
          </p>
          <div>
            <a href="#">Download my QR code</a>
            <Link to="#">Check my reservations</Link>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

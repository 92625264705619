export function Logo({ className, backgorundColorClassName, colorClassName }) {
  return (
    <svg
      viewBox="0 0 112 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="100%" height="100%" className={backgorundColorClassName} />
      <path
        d="M4.50391 0L6.7317 4.52642L10.5674 0H4.50391ZM5.10444 0.377201H9.75375L6.80919 3.85143L5.10444 0.377201Z"
        className={colorClassName}
      />
      <path
        d="M11.3036 16.0807L11.1099 15.505L11.3036 14.9292L11.3811 14.6811L14.6259 5.07236H6.95459L10.2382 14.6811L10.3253 14.9193L10.5191 15.495L10.3253 16.0708L10.2382 16.309L6.95459 25.9177H14.6259L11.3908 16.3189L11.3036 16.0807ZM7.47764 5.44956H14.1126L10.9162 14.9193L10.8096 15.227L10.7128 14.9292L7.47764 5.44956ZM7.47764 25.5504L10.7128 16.0708L10.8096 15.773L10.9162 16.0807L14.1126 25.5504H7.47764Z"
        className={colorClassName}
      />
      <path
        d="M10.8191 0.297791L7.10938 4.66539H14.5095L10.8191 0.297791ZM10.8191 0.873519L13.7153 4.28818H7.923L10.8191 0.873519Z"
        className={colorClassName}
      />
      <path
        d="M11.0615 0L14.8682 4.48671L17.125 0H11.0615ZM14.7907 3.82165L11.8752 0.377201H16.5245L14.7907 3.82165Z"
        className={colorClassName}
      />
      <path
        d="M4.25238 0.337494L0.261719 4.66538H6.38331L4.25238 0.337494ZM4.15552 0.982707L5.78278 4.28818H1.11409L4.15552 0.982707Z"
        className={colorClassName}
      />
      <path
        d="M21.6292 5.07236H15.0136L11.6719 14.9888L11.4976 15.495L11.6719 16.0013L15.0136 25.9177H21.6292L12.1368 15.495L21.6292 5.07236ZM20.7768 25.5504H15.2751L12.04 15.9516L20.7768 25.5504ZM15.2848 5.44956H20.7865L12.0497 15.0483L15.2848 5.44956Z"
        className={colorClassName}
      />
      <path
        d="M9.94759 14.9987L6.55747 5.07236H0L9.49235 15.495L0 25.9177H6.56715L9.95728 15.9913L10.1316 15.4851L9.94759 14.9987ZM0.852374 5.44956H6.30563L9.57952 15.0285L0.852374 5.44956ZM6.30563 25.5504H0.852374L9.57952 15.9715L6.30563 25.5504Z"
        className={colorClassName}
      />
      <path
        d="M17.3766 0.327576L15.1973 4.66539H21.3576L17.3766 0.327576ZM17.4638 0.982715L20.5052 4.28819H15.7978L17.4638 0.982715Z"
        className={colorClassName}
      />
      <path
        d="M6.73219 26.4736L4.50439 31H10.5679L6.73219 26.4736ZM6.80968 27.1486L9.75424 30.6228H5.10493L6.80968 27.1486Z"
        className={colorClassName}
      />
      <path
        d="M7.10986 26.3346L10.8099 30.7022L14.51 26.3346H7.10986ZM7.92349 26.7118H13.7061L10.8099 30.1265L7.92349 26.7118Z"
        className={colorClassName}
      />
      <path
        d="M14.8681 26.5034L11.0615 30.9901H17.125L14.8681 26.5034ZM14.7907 27.1784L16.5245 30.6228H11.8752L14.7907 27.1784Z"
        className={colorClassName}
      />
      <path
        d="M0.261719 26.3346L4.24269 30.6625L6.37363 26.3346H0.261719ZM4.15552 30.0074L1.11409 26.7019H5.77309L4.15552 30.0074Z"
        className={colorClassName}
      />
      <path
        d="M15.1978 26.3346L17.3771 30.6724L21.3678 26.3346H15.1978ZM15.808 26.7118H20.5154L17.474 30.0173L15.808 26.7118Z"
        className={colorClassName}
      />
      <path
        d="M24.5249 26.2453V26.0467C25.7841 26.0467 26.8786 26.0467 26.8786 23.2376V7.73263C26.8786 4.92347 25.7841 4.92347 24.5249 4.92347V4.72495H28.9805L35.3055 25.9276L41.6015 4.72495H45.2434V4.92347C43.9842 4.92347 43.9842 4.92347 43.9842 7.73263V23.1979C43.9842 26.0368 43.9842 26.0368 45.2434 26.0368V26.2353H40.3423V26.0368C41.5724 26.0368 41.6015 25.8085 41.6015 22.6519C41.6015 19.0387 41.6886 9.28114 41.6886 5.38008H41.6015L35.383 26.2453H33.0002L27.1595 6.32309H27.0723V23.2277C27.0723 26.0368 28.1669 26.0368 29.4261 26.0368V26.2353H24.5249V26.2453Z"
        className={colorClassName}
      />
      <path
        d="M52.1015 11.6039C55.2108 11.6039 56.7799 13.6686 56.7799 15.9914C56.7799 19.1479 52.1015 19.8626 48.5177 19.952C48.6339 22.6519 49.2441 26.3247 52.1015 26.3247C55.2689 26.3247 56.5862 22.6222 56.5862 19.7534H56.7799C56.7799 22.7711 55.4626 26.5232 52.1015 26.5232C48.2077 26.553 45.9702 23.7935 45.9702 18.7807C45.9702 14.2741 48.3821 11.6039 52.1015 11.6039ZM48.4886 18.7807C48.4886 19.0685 48.4886 19.4159 48.5177 19.7534C52.9442 19.6641 54.6199 18.8303 54.6199 15.0186C54.6199 12.6065 53.9806 11.8024 52.1015 11.8024C49.0504 11.8124 48.4886 16.0509 48.4886 18.7807Z"
        className={colorClassName}
      />
      <path
        d="M58.4165 9.53923L58.9783 4.71503H74.4857L75.0475 9.53923H74.8538C74.4082 5.69774 72.81 4.92348 69.5652 4.92348H67.9088L67.9379 23.2277C67.9379 26.0368 67.9379 26.0368 69.1971 26.0368V26.2354H64.2959V26.0368C65.5261 26.0368 65.5261 25.8383 65.5551 23.2277V4.92348H63.8988C60.654 4.92348 59.0558 5.69774 58.6102 9.53923H58.4165Z"
        className={colorClassName}
      />
      <path
        d="M74.9897 26.2453V26.0467C76.2199 26.0467 76.2489 25.8482 76.2489 23.2376V14.9193C76.2489 12.3087 76.2489 12.1101 75.0188 12.1101V11.9116H78.6317V23.2277C78.6317 26.0368 78.6317 26.0368 79.8909 26.0368V26.2353H74.9897V26.2453ZM76.0262 8.8543C76.0262 8.05026 76.6461 7.41498 77.4306 7.41498C78.2152 7.41498 78.8351 8.05026 78.8351 8.8543C78.8351 9.65833 78.2152 10.2936 77.4306 10.2936C76.6461 10.2936 76.0262 9.65833 76.0262 8.8543Z"
        className={colorClassName}
      />
      <path
        d="M80.1718 26.0368C81.4019 26.0368 81.431 25.8383 81.431 23.2277V14.9094C81.431 12.2988 81.431 12.1002 80.2009 12.1002V11.9017H83.62L83.8138 14.83V14.8895C84.3465 12.8546 85.9738 11.594 87.6204 11.594C90.0322 11.594 91.3689 12.8546 91.8241 15.4355C92.2116 13.0532 93.9551 11.594 95.7761 11.594C98.74 11.594 100.086 13.5197 100.115 17.5002L100.144 23.2376C100.144 25.8482 100.174 26.0468 101.404 26.0468V26.2453H96.5025V26.0468C97.7326 26.0468 97.7617 26.0468 97.7617 23.2376V18.7906C97.7617 15.0285 97.3936 12.0506 95.3208 12.0208C93.4998 11.991 91.9888 13.7182 91.9307 16.3487C91.9597 16.6961 91.9888 17.0932 91.9888 17.5002L92.0179 23.2277C92.0179 25.8383 92.0469 26.0368 93.277 26.0368V26.2354H88.3759V26.0368C89.606 26.0368 89.6641 26.0368 89.6351 23.2277L89.606 18.7807C89.577 15.0186 89.267 12.0407 87.1942 12.0109C85.3442 11.9811 83.8041 13.7579 83.8041 16.4579V23.2277C83.8041 26.0368 83.8041 26.0368 85.0633 26.0368V26.2354H80.1621V26.0368H80.1718Z"
        className={colorClassName}
      />
      <path
        d="M107.322 11.6039C110.431 11.6039 112 13.6686 112 15.9914C112 19.1479 107.322 19.8626 103.738 19.952C103.854 22.6519 104.464 26.3247 107.322 26.3247C110.489 26.3247 111.806 22.6222 111.806 19.7534H112C112 22.7711 110.683 26.5232 107.322 26.5232C103.428 26.553 101.19 23.7935 101.19 18.7807C101.19 14.2741 103.593 11.6039 107.322 11.6039ZM103.709 18.7807C103.709 19.0685 103.709 19.4159 103.738 19.7534C108.164 19.6641 109.84 18.8303 109.84 15.0186C109.84 12.6065 109.191 11.8024 107.322 11.8024C104.271 11.8124 103.709 16.0509 103.709 18.7807Z"
        className={colorClassName}
      />
    </svg>
  );
}

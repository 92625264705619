import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../Component/header';
import { EmailStep, PasswordStep, TokenStep } from '../Component/Steps';
import useSteps from '../hooks/useSteps';
import './login.css';

export default function ResetPassword() {
  const { currentStep, next /*, previous */ } = useSteps({ maxSteps: 3 });
  const [state, setState] = useState({
    email: '',
    token: '',
  });
  const history = useHistory();

  function emailStepSuccessHandler(values) {
    setState({ email: values.email });
    next();
  }

  function tokenStepSuccessHandler(values) {
    setState({ email: values.email, token: values.token });
    next();
  }

  function passwordStepSuccessHandler() {
    history.push('/');
  }

  return (
    <header>
      <Header showMainLinksSection={false} />
      <div className="bg"></div>
      <div className="hero">
        {currentStep == 1 ? (
          <EmailStep onSuccess={emailStepSuccessHandler} />
        ) : null}

        {currentStep == 2 ? (
          <TokenStep email={state.email} onSuccess={tokenStepSuccessHandler} />
        ) : null}

        {currentStep == 3 ? (
          <PasswordStep
            email={state.email}
            token={state.token}
            onSuccess={passwordStepSuccessHandler}
          />
        ) : null}
      </div>
    </header>
  );
}

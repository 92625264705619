import { useFormik } from "formik";
import * as yup from "yup";
import * as api from "../utils/api";

const validationSchema = yup.object({
  email: yup.string().email().required().default(""),
});

export default function useEmailStepForm(props) {
  const { onSuccess, onError } = props;

  const formik = useFormik({
    initialValues: validationSchema.getDefault(),
    validationSchema: validationSchema,
    async onSubmit(values) {
      try {
        await api.sendResetPassword(values.email);

        if (typeof onSuccess == "function") {
          onSuccess(values);
        }
      } catch (error) {
        if (typeof onError == "function") {
          onError(error);
        }
      }
    },
  });

  return { formik };
}

import { useFormik } from "formik";
import * as yup from "yup";
import * as api from "../utils/api";

const validationSchema = yup.object({
  email: yup.string().email().required().default(""),
  token: yup.string().required().default(""),
});

export default function useTokenStepForm(props) {
  const { initialValues, onSuccess, onError } = props;

  const formik = useFormik({
    initialValues: { ...validationSchema.getDefault(), ...initialValues },
    validationSchema: validationSchema,
    async onSubmit(values) {
      try {
        if (typeof onSuccess == "function") {
          onSuccess(values);
        }
      } catch (error) {
        if (typeof onError == "function") {
          onError(error);
        }
      }
    },
  });

  function resendResetPassword() {
    return api.sendResetPassword(formik.values.email);
  }

  return { formik, resendResetPassword };
}

import React, { Component } from 'react';
import Footer from '../Component/footer/footer';
import Header from '../Component/header';

class Support extends Component {
  render() {
    return (
      <div>
        <Header showMainLinksSection={false} />
        <div className="cbg">
          <h1 style={{ fontFamily: 'paradiz' }}>Contactez Nous</h1>
          <p style={{ fontFamily: 'Segoe' }}>
            Besoin de nous contacter? remplissez le formulaire avec votre
            demande ou trouvez ci-dessous l'adresse e-mail du service que vous
            souhaitez contacter.
          </p>

          <div class="container">
            <form action="action_page.php">
              <label for="fname">First Name : </label>
              <br />
              <input
                type="text"
                id="fname"
                name="firstname"
                placeholder="Your name.."
              />
              <br />
              <label for="lname">Last Name : </label>
              <br />

              <input
                type="text"
                id="lname"
                name="lastname"
                placeholder="Your last name.."
              />
              <br />
              <label for="lname">Address Email : </label>
              <br />

              <input
                type="text"
                id="lname"
                name="lastname"
                placeholder="Your last email.."
              />
              <br />

              <label for="subject">En quoi pouvons-nous vous aider?</label>
              <br />
              <textarea
                id="subject"
                name="subject"
                placeholder="Write something.."
                style={{ height: 200 }}
              ></textarea>
              <br />

              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Support;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Advantage from '../Component/advantage';
import Download from '../Component/download/download';
import Footer from '../Component/footer/footer';
import Header from '../Component/header';
import Help from '../Component/Help';
import Payment from '../Component/payment/payment';
import Promotion from '../Component/promotion/promotion';
import Salons from '../Component/salon';
import Testmonials from '../Component/testimonials';
import './home.css';

class Home extends Component {
  render() {
    return (
      <>
        <header className="landing white-bg" id="landing">
          <Header textColor="black" />
          <div
            style={{ textAlign: 'center', marginTop: '10%' }}
            className="landing-hero"
          >
            <div className="content">
              <h1>Envie de prendre soin de vous!</h1>
              <p>
              N’attendez plus… Sur Metime, vous pouvez réserver votre place dans les meilleurs salons de beauté et vous pouvez également profiter de nos services à domicile.
        
        </p>
			  <Link to="/login">
			  <button 
			  className='home-button'
			  >
			 Reservez Maintenant
			</button></Link>
            </div>
            <div>
              <span>MeTime</span>
            </div>
          </div>
        </header>
        <Salons />
        <Promotion />
        <Advantage />
        <Testmonials />
        <Download />
        <Payment />
        <Footer />
      </>
    );
  }
}

export default Home;

import React from "react";
import Testmonial from "./testimonial";

const testmonials = (props) => {
  return (
    <section id="reviews" className="testimonials">
      <Testmonial />
    </section>
  );
};
export default testmonials;

import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import ServiceCardItem from '../ServiceCardItem';
import service from './service';
import './services.css';

export default function Cart() {
  const { isEmpty, items, removeItem } = useCart();
  return (
    <div id="cart" className="note">
      <span>Vous avez choisi:</span>
      <div>
        {isEmpty ? (
          <>
            <img src="./assets/bro.png" alt="" />
            <p>Vous n'avez rien choisi </p>
          </>
        ) : null}

        {items.map(function createCartItem(service) {
          const { id, title, price, duration } = service;

          return (
            <ServiceCardItem
              key={id}
              id={id}
              title={title}
              price={price}
              duration={duration}
              onRemoveClick={removeItem}
            />
          );
        })}
        {!isEmpty ? (
          <Link className="button" to="/booking">
            Confirmer
          </Link>
        ) : null}
      </div>
    </div>
  );
}

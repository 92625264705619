import { useState } from "react";

export default function useSteps(props) {
  const { maxSteps } = props;

  const [currentStep, setCurrentStep] = useState(1);

  function next() {
    if (currentStep < maxSteps) {
      setCurrentStep(currentStep + 1);
    }
  }

  function previous() {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  }

  return { currentStep, previous, next };
}

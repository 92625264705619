import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderAuth from "../Component/headerAuth";
import { openNotificationWithIcon, postModule } from "../utils/apiService";
import "./login.css";

class Signup extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    repeatPassword: "",
    cityId: 1,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  signupHandler(e) {
    e.preventDefault();

    var updatedDate = { ...this.state };

    if (this.state.password !== this.state.repeatPassword) {
      openNotificationWithIcon("warning", "Your passwords Are Not Matching");
      return;
    }
    delete updatedDate["repeatPassword"];
    delete updatedDate["firstname"];
    delete updatedDate["lastname"];
    postModule({ module: "/auth/clients/register", data: updatedDate })
      .then((response) => {
        if (response.isErr) {
          try {
            response.response.message.map((msg) =>
              openNotificationWithIcon("warning", msg)
            );
          } catch {
            return;
          }
        } else {
          openNotificationWithIcon(
            "success",
            "Your Account Is Created Please Confirm Your Email"
          );
          window.location.href = "/login";
        }
      })
      .catch((err) => console.log(err));
  }

  togglePassword(e) {
    let input = e.target.previousElementSibling;
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
    } else {
      input.setAttribute("type", "password");
    }
  }

  render() {
    return (
      <header style={{ overflow: window.innerWidth > 800 ? "hidden" : "auto" }}>
        <HeaderAuth showMainLinksSection={false} />

        <div className="bg"></div>
        <div className="hero">
          <div className="signup">
            <h1 className="form-title">Créer un compte</h1>
            <p className="form-sub-title">
              Vous avez déjà un compte? <Link to="/login">Connexion</Link>
            </p>
            <form className="hero-form">
              <div className="form-group">
                <input
                  onChange={(e) =>
                    this.setState({ ...this.state, firstName: e.target.value })
                  }
                  type="text"
                  placeholder="Prénom"
                  autoFocus
                  required
                />
                <input
                  onChange={(e) =>
                    this.setState({ ...this.state, lastName: e.target.value })
                  }
                  type="text"
                  placeholder="Nom"
                  required
                />
              </div>
              <div>
                <input
                  onChange={(e) =>
                    this.setState({ ...this.state, email: e.target.value })
                  }
                  type="email"
                  placeholder="Adresse email"
                  required
                />
              </div>
              <div>
                <input
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      phoneNumber: e.target.value,
                    })
                  }
                  type="tel"
                  placeholder="Numéro De Téléphone"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  onChange={(e) =>
                    this.setState({ ...this.state, password: e.target.value })
                  }
                  type="password"
                  placeholder="Votre Mot De Passe"
                  required
                />
                <img
                  src="./assets/eye.png"
                  alt="toggle password"
                  onClick={this.togglePassword}
                />
              </div>
              <div className="form-group">
                <input
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      repeatPassword: e.target.value,
                    })
                  }
                  type="password"
                  placeholder="Répéter Votre Mot De Passe"
                  required
                />
                <img
                  src="./assets/eye.png"
                  alt="toggle password"
                  onClick={this.togglePassword}
                />
              </div>
              <input
                onClick={this.signupHandler.bind(this)}
                type="submit"
                value="S'inscrire"
                style={{ color: "#fff", letterSpacing: "1.5px" }}
              />
            </form>
            <div className="app-horizontal-devider">or</div>
            <div>
              <a
                href={`${process.env.REACT_APP_API_URL}/auth/google`}
                className="app-auth-link-container app-auth-link-container-google"
              >
                <span className="app-auth-link-icon app-auth-link-icon-google"></span>
                <span className="app-auth-link-text app-color-black">
                  Google
                </span>
              </a>
            </div>

            <div>
              <a
                href={`${process.env.REACT_APP_API_URL}/auth/facebook`}
                className="app-auth-link-container app-auth-link-container-facebook"
              >
                <span className="app-auth-link-icon app-auth-link-icon-facebook"></span>
                <span className="app-auth-link-text app-color-white">
                  Facebook
                </span>
              </a>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Signup;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Component/header";
import useEmailVerificationForm from "../hooks/useEmailVerificationForm";
import useQuery from "../hooks/useQuery";
import "./login.css";

export default function EmailVerification() {
  const history = useHistory();
  const query = useQuery();
  const { formik } = useEmailVerificationForm({
    onSuccess,
    onError,
  });

  useEffect(
    function submitForm() {
      if (!formik.errors.email && !formik.errors.token) {
        formik.submitForm();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.errors.email, formik.errors.token]
  );

  useEffect(
    function setValues() {
      const email = query.get("email");
      const token = query.get("token");

      if (email && token) {
        formik.setValues({
          email,
          token,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query]
  );

  function onSuccess() {
    history.push("/login");
  }

  function onError(error) {
    console.log(error);
  }

  return (
    <header>
      <Header showMainLinksSection={false} />
      <div className="bg"></div>
      <div className="hero">
        <div className="verification">
          <h1 className="form-title">Email verification</h1>
          <p className="form-sub-title">
            Enter the 6 digits we sent to your email
          </p>
          <form className="hero-form" onSubmit={formik.handleSubmit}>
            <div>
              <input
                type="email"
                placeholder="Email"
                autoFocus
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Verification code"
                name="token"
                value={formik.values.token}
                onChange={formik.handleChange}
              />
            </div>

            <input type="submit" value="Verify my email" />
          </form>
          <p>
            Didn't receive the email?
            <a href="#" className="forgot-resend">
              Resend
            </a>
          </p>
        </div>
      </div>
    </header>
  );
}

import { useEffect, useState } from "react";

/**
 * Returns the sum of a and b
 * @param {object} props has a memoized fetcher(() => Promise) function
 * @returns {object} { isLoading: boolean, data?: any, error?: any }
 */
export default function useApi(props) {
  const { fetcher } = props;
  const [state, setState] = useState({
    isLoading: true,
  });

  useEffect(
    function fetchData() {
      setState({
        isLoading: true,
      });

      fetcher()
        .then(function onSuccess(data) {
          setState({
            isLoading: false,
            data: data,
          });
        })
        .catch(function onError(error) {
          setState({
            isLoading: false,
            error: error,
          });
        });
    },
    [fetcher]
  );

  return state;
}

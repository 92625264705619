import React from "react";

export default function ServiceReview() {
  return (
    <div className="review">
      <div className="profile">
        <img src="./assets/Ellipse 20.png" alt="avatar" />
        <div>
          <h4>Annette Black</h4>
          <div className="rating">
            <img src="./assets/rating.png" alt="rating" />
          </div>
        </div>
      </div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas quam
        egestas a aliquet duis potenti. Vulputate adipiscing congue urna,
        habitant malesuada tincidunt massa tincidunt. Ac enim maecenas at nec
        feugiat cras risus condimentum arcu. Quam viverra volutpat suspendisse
        pellentesque consectetur proin malesuada. Eu et sollicitudin aliquet sed
        lobortis dui in nisl. Donec nec feugiat odio vel, sit cras neque, tortor
        metus. Tempus, blandit amet feugiat nisi, feugiat nibh cursus lectus.
        Elit laoreet laoreet faucibus feugiat morbi turpis sed eu quis. Purus
        tortor et vitae id eget at convallis volutpat ut. Tellus dictumst dictum
        lobortis praesent lorem tristique. Nisi nec metus venenatis massa ac.
        Gravida tincidunt habitant malesuada lectus bibendum volutpat lacus,
        quam. In eros parturient bibendum tincidunt felis elementum neque.
      </p>
    </div>
  );
}

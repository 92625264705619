import React from "react";
import "./payment.css";

const payment = (props) => {
  return (
    <section className="payment">
      <h2>On accepte</h2>
      <div className="container">
        <img style={{width :150 , height : 150}} src="./assets/CMI.svg" alt="" />
        <img style={{width :150 , height : 150}}  src="./assets/mastercard foundation.svg" alt="" />
        <img style={{width :150 , height : 150}}  src="./assets/VISA.svg" alt="" />
      </div>
    </section>
  );
};
export default payment;

import { Link } from "react-router-dom";

const salon = (props) => {
  const { id, address, name, image } = props;

  return (
    <div className="salon-card">
      <div className="img">
        <img src={image} alt="" />
        <img
          src={
            props.favorite ? "./assets/Vectored.png" : "./assets/like icon.svg"
          }
          alt="favorite"
          className="heart"
        />
      </div>
      <div className="content-sln">
        <div>
          <h3>{name}</h3>
          {/* <span>hairdresser</span> */}
          <div className="rating">
            <img src="./assets/rating.png" alt="rating" />
          </div>
        </div>
        {props.show ? (
          <p style={{ display: "block" }}>
            <img src="./assets/location.png" alt="location" />
            <span>{address}</span>
          </p>
        ) : (
          <p style={{ display: "block" }}>
            <img src="./assets/location.png" alt="location" />
            <span>Rabat</span>
          </p>
        )}
        <Link to={`/${id}/services`} className="btn-s">
          Réservez maintenant!
        </Link>
      </div>
    </div>
  );
};

export default salon;

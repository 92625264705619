import React, { useEffect } from "react";
import "./advantage.css";

const Advantage = (props) => {
  let AdvantagesBtns = "";
  let AdvantagesSlides = "";
  let AdvantagesDivs = "";

  useEffect(() => {
    AdvantagesBtns = document.querySelectorAll(
      ".advantages .advantages-btns button"
    );
    AdvantagesSlides = document.querySelector(".advantages .advantages-slides");
    AdvantagesDivs = document.querySelectorAll(
      ".advantages .advantages-slides .content"
    );
  }, [props]);

  let advantages = 1;
  // moving function
  function advantagesSlide() {
    //reset the transition to the default
    AdvantagesSlides.style.transition = "transform 0.5s";
    AdvantagesSlides.style.transform = `translateX(calc(-${advantages} * 100%))`;
  }

  // click event function

  function advantagesBtnClick(e) {
    if (e.target.id === "advantages-left") {
      advantages--;
    } else if (e.target.id === "advantages-right") {
      advantages++;
    }
    e.target.disabled = true;
    advantagesSlide();
  }

  function handleTransition() {
    if (AdvantagesDivs[advantages].id === "content-f") {
      AdvantagesSlides.style.transition = "none";
      advantages = 3;
      AdvantagesSlides.style.transform = `translateX(calc(-${advantages} * 100%))`;
    } else if (AdvantagesDivs[advantages].id === "content-l") {
      AdvantagesSlides.style.transition = "none";
      advantages = 1;
      AdvantagesSlides.style.transform = `translateX(calc(-${advantages} * 100%))`;
    }
    AdvantagesBtns[0].disabled = false;
    AdvantagesBtns[1].disabled = false;
  }
  return (
    <section id="avantages" className="advantages">
      <h2>Profitez de nos avantages</h2>
      <div className="container">
        <div className="advantages-btns">
          <button onClick={advantagesBtnClick} id="advantages-left">
            <img
              style={{ width: 36, height: 36 }}
              src="assets/black flesh on left.svg"
              alt="left arrow"
            />
          </button>
          <button onClick={advantagesBtnClick} id="advantages-right">
            <img
              style={{ width: 36, height: 36 }}
              src="assets/black flesh on right.svg"
              alt="right arrow"
            />
          </button>
        </div>
        <div className="advantages-slider" style={{ marginTop: 30 }}>
          <div className="advantages-slides" onTransitionEnd={handleTransition}>
            <div className="content" id="content-f">
              <h3>
                3. Planifiez votre rendez-vous à l'avance et sécurisez votre
                place.{" "}
              </h3>
              <img src="assets/Frame 137.png" alt="" />
            </div>
            <div className="content">
              <h3>
                Planifiez votre rendez-vous à l'avance et organisez votre temps.
              </h3>
              <img src="assets/Frame 137.png" alt="" />
            </div>
            <div className="content">
              <h3>
                Faites de vos soins personnels une partie de votre vie
                quotidienne .
              </h3>
              <img src="assets/Frame 138.png" alt="" />
            </div>
            <div className="content">
              <h3>
                Profitez de nos promotion et de nos services exceptionnelle .
              </h3>
              <img src="assets/Frame 13666.png" alt="" />
            </div>
            <div className="content" id="content-l">
              <h3>
                Planifiez votre rendez-vous à l'avance et organisez votre temps.
              </h3>
              <img src="assets/Frame 137.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantage;

import { useFormik } from "formik";
import * as yup from "yup";
import * as api from "../utils/api";
import { openNotificationWithIcon } from "../utils/apiService";

const validationSchema = yup.object({
  roomId: yup.number().integer().required(),
  date: yup.date().required().default(""),
  time: yup.string().required().default(""),
  servicesIds: yup.array(yup.number().integer().min(1)).min(1),
});

export default function useReservationForm(params = {}) {
  const { initialValues = {}, onSuccess } = params;

  const formik = useFormik({
    initialValues: {
      ...validationSchema.getDefault(),
      ...initialValues,
    },
    validationSchema: validationSchema,
    async onSubmit(values) {
      try {
        const { roomId, date, time, servicesIds } = values;

        await api.addReservations(roomId, {
          startDate: `${date}T${time}Z`,
          servicesIds,
        });
        if (typeof onSuccess == "function") {
          onSuccess();
        }
      } catch (error) {
       openNotificationWithIcon("warning" , "error" , error.response.data.message)
      }
    },
  });

  return { formik };
}

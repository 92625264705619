import { format } from "date-fns";
// import useBoolean from "../hooks/useBoolean";
// import Modal from "./Modal";

export default function CompletedReservation(props) {
  const { /* id, */ address, startDate, duration, total } = props;

  return (
    <div>
      <div className="wrapper">
        <p>
          <img src="./assets/ic_outline-location-onq.png" alt="" />
          <span>{address}</span>
        </p>
        <p>
          <img src="./assets/ic_baseline-access-times.png" alt="" />
          <span>
            {format(new Date(startDate), "EEEE, dd MMMM yyyy at hh:mma")}
          </span>
        </p>
        <p>
          <img src="./assets/ic_outline-timer.png" alt="" />
          <span>Duration: {duration}min</span>
        </p>
        <h5>Total {total}DH</h5>
      </div>
      <button>Leave a feedback</button>
    </div>
  );
}

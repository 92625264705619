import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import clsx from "clsx";
import MenuIcon from "../assets/MenuIcon";
import SignInIcon from "../assets/SignInIcon";
import SignupIcon from "../assets/SignupIcon";
import XIcon from "../assets/XIcon";
import ChevronDownIcon from "../assets/ChevronDownIcon";
import ChevronRightIcon from "../assets/ChevronRightIcon";
import SignOutIcon from "../assets/SignOutIcon";
import { Logo } from "../assets/Logo";
import { useAuth } from "../hooks/useAuth";
import useBoolean from "../hooks/useBoolean";

const notLoggedInMainLinks = [
  {
    text: "Accueil",
    target: "/",
    isReactRouterLink: true,
  },
  {
    text: "Salon De Beauté",
    target: "/#best",
  },
  {
    text: "Avantages",
    target: "/#avantages",
  },
  {
    text: "Evaluation",
    target: "/#reviews",
  },
  {
    text: "Télécharger L’App",
    target: "/#download",
  },
  {
    text: "À propos De Nous",
    target: "/about",
    isReactRouterLink: true,
  },
];

const loggedInMainLinks = [
  {
    text: "Accueil",
    target: "/",
    isReactRouterLink: true,
  },
  {
    text: "Résérvez Maintenant",
    target: "/startbooking",
    isReactRouterLink: true,
  },
  {
    text: "Mes Résérvations",
    target: "/reservations",
    isReactRouterLink: true,
  },
];

function getTextColor({ textColor }) {
  if (!textColor) {
    return "color: black";
  }

  return `color: ${textColor}`;
}

function Header({ className, showMainLinksSection = true }) {
  const auth = useAuth();
  const history = useHistory();
  const {
    value: showNavBarValue,
    setTrue: showNavBar,
    setFalse: hideNavBar,
  } = useBoolean(false);

  function renderMainLinks(links) {
    return links.map(function renderLink(link, key) {
      const { text, target, isReactRouterLink } = link;
      let element;

      if (isReactRouterLink) {
        element = (
          <Link to={target} className="link-item-content">
            {text}
          </Link>
        );
      } else {
        element = (
          <a href={target} className="link-item-content">
            {text}
          </a>
        );
      }

      return (
        <li className="link-item" key={key}>
          {element}
        </li>
      );
    });
  }

  function getFullName(firstName, lastName) {
    return `${firstName} ${lastName}`;
  }

  function logoutHandler() {
    auth.logout();
    history.push("/login");
  }

  return (
    <header className={className}>
      <div className="app-header-logo-out">
        <div className="">
          <Link to="/">
            <Logo
              className=" app-header-logo"
              colorClassName="app-header-logo-out-color"
              backgorundColorClassName="app-header-logo-background-color"
            />
          </Link>
        </div>
        {showNavBarValue ? (
          <XIcon className="app-menu-icon" onClick={hideNavBar} />
        ) : (
          <MenuIcon className="app-menu-icon" onClick={showNavBar} />
        )}
      </div>
      <nav
        className={clsx("app-nav-container", {
          "app-nav-show": showNavBarValue,
        })}
      >
        <Link to="/">
          <Logo
            className="app-header-logo"
            colorClassName="app-header-logo-color"
            backgorundColorClassName="app-header-logo-background-color"
          />
        </Link>
        {showMainLinksSection ? (
          <ul className="links-container">
            {renderMainLinks(
              auth.isLoggedIn ? loggedInMainLinks : notLoggedInMainLinks
            )}
          </ul>
        ) : null}

        {!auth.isLoggedIn ? (
          <ul className="links-container">
            <li className="link-item">
              <Link to="/login" className="link-item-content">
                <SignInIcon className="link-item-icon" />
                Connexion
              </Link>
            </li>
            <li className="link-item">
              <Link className="link-item-content" to="/signup">
                <SignupIcon className="link-item-icon" />
                S'inscrire
              </Link>
            </li>
          </ul>
        ) : null}

        {auth.isLoggedIn ? (
          <div className="link-item link-item-content link-item-dropdown">
            <SignInIcon className="link-item-icon" />
            {getFullName(auth.user.firstName, auth.user.lastName)}
            <ChevronDownIcon className="link-item-icon" />

            <div className="link-item-dropdown-container">
              <ul className="link-item-dropdown-content">
                <li className="link-item">
                  <Link
                    to="/account"
                    className="link-item-content link-item-doropdown-content"
                  >
                    Profile <ChevronRightIcon className="link-item-icon" />
                  </Link>
                </li>
                <li className="link-item">
                  <button
                    onClick={logoutHandler}
                    className="link-item-content link-item-doropdown-content"
                  >
                    Déconnexion <SignOutIcon className="link-item-icon" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
      </nav>
    </header>
  );
}

export default styled(Header)`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 40px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: auto;
  height: auto;
  z-index: 1;

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .app-nav-container {
    display: flex;
    justify-content: space-between;
  }

  .app-header-logo {
    width: 150px;
  }

  .app-header-logo-color {
    fill: #fff;
  }
  .app-header-logo-out-color {
    fill: black;
  }
  .app-header-logo-background-color {
    fill: transparent;
  }

  .links-container {
    list-style-type: none;
    display: flex;
    align-items: center;
  }

  .link-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    position: relative;
  }
  .link-item:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 0;
    background: #fff;
    transition: all 500ms;
  }
  .link-item:hover:after {
    width: calc(100% - 20px);
  }
  .link-item-content {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-size: 17px;
    letter-spacing: 1.3px;
  }

  .link-item-icon {
    height: 16px;
    margin-right: 5px;
  }
  .app-header-logo-out {
    display: none;
    background-color: #fff;
  }
  .app-header-logo-out > div {
    max-width: 190px;
  }
  .app-menu-icon {
    position: fixed;
    top: 0;
    right: 0;
    display: none;
    cursor: pointer;
  }

  .link-item-dropdown {
    position: relative;
  }

  .link-item-dropdown-container {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: max-content;
    padding: 8px;
  }

  .link-item-dropdown-content {
    list-style-type: none;
    background-color: #555;
    color: white;
    padding: 8px;
    border-radius: 6px;
  }

  .link-item-doropdown-content {
    color: white;
    padding: 5px 0;
  }

  .link-item-dropdown:hover .link-item-dropdown-container {
    display: block;
  }

  @media screen and (max-width: 1235px) {
    .app-header-logo {
      margin-bottom: 20px;
      width: 100%;
      height: 50px;
    }

    .app-nav-container {
      position: fixed;
      top: 0;
      left: -100%;
      width: 280px;
      height: 100%;
      margin: 0;
      background-color: #333;
      padding: 20px;
      overflow: hidden;
      transition: 0.5s;

      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .app-header-logo-color {
      fill: white;
    }

    .links-container {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 50px;
    }

    .link-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px;
    }

    .link-item-content {
      color: white;
    }
    .app-header-logo-out {
      position: fixed;
      width: 100%;
      padding: 10px;
      top: 0px;
      left: 0px;
      display: block;
      background-color: #fff;
      height: 70px;
    }
    .app-header-logo-out > div {
      max-width: 190px;
    }
    .app-menu-icon {
      display: block;
      margin: 10px;
      width: 35px;
    }
  }

  .app-nav-show {
    left: 0;
  }
  @media screen and (max-width: 768px) {
    .app-header-logo-out {
      padding-left: 12px !important;
    }
    .app-header-logo-out > div {
      max-width: 120px;
    }
  }
`;

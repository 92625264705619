import React from "react";
import { Link } from "react-router-dom";
import { salons } from "../../utils/constant";
import Salon from "./salon";
import "./salon.css";

const Salons = (props) => {
  return (
    <>
      <section id="best" className="salons">
        <h2> Nos Meilleurs Salons De Beauté </h2>
        <div className="container">
          {salons.map((s, index) => (
            <Salon show={props.show} {...s} key={index} />
          ))}
        </div>
        {props.search ? (
          <div className="pagination">
            <img src="./assets/goleft.png" alt="" />
            <h4>
              <span> 1 </span> of 12
            </h4>
            <img src="./assets/goright.png" alt="" />
          </div>
        ) : null}
        <div className="more-button">
          <Link to="/startbooking">
            <button>Voir D'autres Salons</button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Salons;

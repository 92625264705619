export const salons = [ 
    {
        "name" : "Adresse Pour Lui" , 
        address : '31 Lot Habouss, Mag 1,Sidi Moussa, Salé' , 
  id : 7 , 
        image : './assets/pourlui.jpg'
    } , 
    {
        "name" : "Light House" , 
        address : '31 Lot Habouss, Mag 2,Sidi Moussa, Salé' , 
        image : './assets/lighthouse.jpeg' , 
        id : 10
    } ,
    {
        "name" : "Création El Merouani" , 
        address : 'Imm 28, Mag 2, Rue Ouad Baht, Agdal - RABAT' , 
        image : './assets/maison-de-beaute.jpg' , 
        id : 3
    },
    {
        "name" : "Zen ONGLERIE" , 
        address : '33, Rue Melouia, Mag 2, Agdal-RABAT' , 
        id :5,
        image : './assets/Zen_ONGLERIE.jpg'
    },
    {
        "name" : "La Belle Dame Spa" , 
        address : '44 rue moulay ali cherif, Av. Moulay Hassan, Rabat 11000, Morocco' , 
        image : './assets/belle dame.png' , 
        id : 1
    },
    {
        "name" : "Chic Man" , 
        address : 'Imm 34 Bis, Mag 1, Rue Ouad FES, Av Al ABTAL, Agdal - RABAT' , 
        image : './assets/chicman.jpeg' , 
        id : 2
    }
]
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import * as api from "../utils/api";
import { openNotificationWithIcon } from "../utils/apiService";

const authContext = createContext();

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [data, setData] = useState({
    isLoading: true,
    isLoggedIn: false,
    user: undefined,
    error: undefined,
  });

  useEffect(function fetchUserProfile() {
    (async function run() {
      try {
        const { data } = await api.getUserProfile();

        setData({
          isLoading: false,
          isLoggedIn: true,
          user: data.data,
        });
      } catch (error) {
        setData({
          isLoading: false,
          isLoggedIn: false,
          error: error,
        });
      }
    })();
  }, []);

  const login = useCallback(async function login(email, password) {
    try {
      const { data } = await api.login(email, password);

      localStorage.setItem("token", data.accessToken);

      const { data: user } = await api.getUserProfile();

      setData({
        isLoading: false,
        isLoggedIn: true,
        user: user.data,
      });
    } catch (error) {
      openNotificationWithIcon("warning", "Error", "Wrong Credentials");
      setData({
        isLoading: false,
        isLoggedIn: false,
        error: error,
      });
    }
  }, []);

  const logout = useCallback(async function logout() {
    localStorage.removeItem("token");
    setData({
      isLoading: false,
      isLoggedIn: false,
    });
  }, []);

  const googleAuth = useCallback(async function googleAuth(code) {
    const { data } = await api.googleAuth({ code });

    await localStorage.setItem("token", data.accessToken);

    const { data: user } = await api.getUserProfile();

    setData({
      isLoading: false,
      isLoggedIn: true,
      user: user.data,
    });
  }, []);

  const facebookAuth = useCallback(async function googleAuth(code) {
    const { data } = await api.facebookAuth({ code });
    await localStorage.setItem("token", data.accessToken);
    const { data: user } = await api.getUserProfile();

    setData({
      isLoading: false,
      isLoggedIn: true,
      user: user.data,
    });
  }, []);

  return {
    login,
    logout,
    googleAuth,
    facebookAuth,
    ...data,
  };
}

export function AuthProvider(props) {
  const value = useProvideAuth();

  return (
    <authContext.Provider value={value}>{props.children}</authContext.Provider>
  );
}

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useGeolocation from "../hooks/useGeoLocation";
import { getNearbyRooms } from "../utils/api";

function BookingMap({ className }) {
  const history = useHistory();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const [distance, setDistance] = useState("1000");
  const [nearbyRooms, setNearbyRooms] = useState([]);
  const [enableCrrentLocation, setEnableCrrentLocation] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    lat: 33.5722678,
    lng: -7.6570319,
  });
  const [centerPosition, setCenterPosition] = useState({
    lat: 33.5722678,
    lng: -7.6570319,
  });
  const geoLocation = useGeolocation({
    when: enableCrrentLocation,
  });

  useEffect(() => {
    if (!geoLocation || geoLocation.isError) {
      return;
    }

    setCurrentPosition({
      lat: geoLocation.lat,
      lng: geoLocation.lng,
    });
    setCenterPosition({
      lat: geoLocation.lat,
      lng: geoLocation.lng,
    });
  }, [geoLocation]);

  useEffect(() => {
    getNearbyRooms(currentPosition.lat, currentPosition.lng, distance)
      .then((res) => setNearbyRooms(res.data))
      .catch(console.error);
  }, [currentPosition, distance]);

  function selectDistanceChangeHandler(e) {
    setDistance(e.target.value);
  }

  function mapClickHandler(e) {
    setEnableCrrentLocation(false);

    setCurrentPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }

  function cityMarkerClickHandler(room) {
    return function onMarkerClick() {
      history.push(`/${room.id}/services`);
    };
  }

  return (
    <div className={clsx("map", className)}>
      <div className="map-container">
        <button onClick={() => setEnableCrrentLocation(true)}>
          <span>From my current location</span>
          <img src="./assets/loc.png" alt="location icon" />
        </button>
        <div className="select-container">
          <select value={distance} onChange={selectDistanceChangeHandler}>
            <option value="1000">1km</option>
            <option value="2000">2km</option>
            <option value="3000">3km</option>
            <option value="4000">4km</option>
            <option value="5000">5km</option>
            <option value="6000">6km</option>
            <option value="7000">7km</option>
            <option value="8000">8km</option>
            <option value="9000">9km</option>
            <option value="10000">10km</option>
            <option value="11000">11km</option>
          </select>
        </div>
      </div>

      {isLoaded ? (
        <GoogleMap
          onClick={mapClickHandler}
          center={centerPosition}
          zoom={15}
          mapContainerClassName="google-map-container"
        >
          <Marker title="my current position" position={currentPosition} />

          {nearbyRooms.map((room, index) => (
            <Marker
              onClick={cityMarkerClickHandler(room)}
              title={room.name}
              key={index}
              position={{
                lat: room.latitude,
                lng: room.longitude,
              }}
            />
          ))}
        </GoogleMap>
      ) : null}
    </div>
  );
}

export default styled(BookingMap)`
  .select-container {
    margin-left: 8px;
  }

  .google-map-container {
    width: 100%;
    height: 550px;
  }
`;

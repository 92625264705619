import React, { Component } from 'react';
import Footer from '../Component/footer/footer';
import Header from '../Component/header';
import './about.css';

class Support extends Component {
	componentDidUpdate() {
		  window.scrollTo(0, 0)
	  }
	
  render() {
    return (
      <>
        <header className="landingab white-bg" id="landing">
          <Header showMainLinksSection={false} />
          <div
            style={{ textAlign: 'center', marginTop: '20%' }}
            className="landing-hero"
          >
            <div  className="contentab ">
              <h1>
                Notre vision est de rendre les soins de beaute accessibles à
                tous de manière simple et rapide
              </h1>
            </div>
            <div>
              <span>MeTime</span>
            </div>
          </div>
        </header>
        <div className='abtbg'>

        
        <div
          style={{
            textAlign: 'center',
            width: '60%',
            marginLeft: 'auto',
            marginRight: 'auto',
            lineHeight: 1.4,
            padding: '100px 0px 100px 0px',
          }}
          className="about-c"
        >
          <p>
            <span>
              A propos de nous :<br />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              Notre marque est bas&eacute;e sur l&apos;amour de soi et les soins
              personnels, et &agrave; l&rsquo;encouragement des personnes
              &agrave; exprimer leurs sentiments d&apos;amour en cr&eacute;ant
              une connexion avec leur corps. Metime est une marque qui permet
              &agrave; chacun de s&apos;exprimer &agrave; travers la
              beaut&eacute; et le soin de soi et aussi en exprimant sa passion
              pleine de sens, de bonheur et d&apos;excitation. Nous voulions que
              tous les membres de notre communaut&eacute; maintiennent leur
              sant&eacute; mentale afin qu&apos;ils puissent continuer &agrave;
              vivre avec joie, amour et &eacute;nergie positive.
            </strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>Notre &eacute;quipe :</span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>
              Notre &eacute;quipe est la cl&eacute; de notre succ&egrave;s,
              l&apos;esprit positif avec lequel nous nous soutenons mutuellement
              favorise des relations ouvertes, honn&ecirc;tes et significatives.
              Chacun de nos membres est incroyable par lui-m&ecirc;me, mais
              ensemble, nous sommes ce qui fait de Metime le succ&egrave;s
              qu&apos;il est aujourd&apos;hui. Notre &eacute;quipe a une vision
              commune pour tous les membres de notre communaut&eacute; pour se
              sentir heureux et aim&eacute;.
            </strong>
          </p>{' '}
        </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Support;

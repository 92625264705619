import { useCallback, useState } from "react";

export default function useBoolean(defaultValue) {
  const [value, setValue] = useState(Boolean(defaultValue));

  const setTrue = useCallback(function setTrue() {
    setValue(true);
  }, []);

  const setFalse = useCallback(function setFalse() {
    setValue(false);
  }, []);

  const toggle = useCallback(function toggle() {
    setValue((x) => !x);
  }, []);

  return { value, setValue, setTrue, setFalse, toggle };
}

import React from 'react';
import { Link } from 'react-router-dom';
import { BASEAPI } from '../utils/apiService';

export default function BookingCity(props) {
  const { id, label, roomsCount } = props;

  return (
    <Link to={`/cities/${id}/salons`}>
      <div className="card">
        
        <img src={`${BASEAPI}cities/images/${props.image}`}  alt="" />
        <div className="content">
          <h4>
            <img src="./assets/ic_outline-location-on.png" alt="" />
            <span>{label}</span>
          </h4>
          <h5>Nombre de salons {roomsCount}</h5>
        </div>
      </div>
    </Link>
  );
}

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useQuery from "../hooks/useQuery";

export default function FacebookAuth() {
  const query = useQuery();
  const { facebookAuth } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const code = query.get("code");

    if (code) {
      facebookAuth(code)
        .then(() => history.push("/"))
        .catch(() => console.log("error"));
    }
  }, [query, facebookAuth, history]);

  return null;
}

import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth } from "./useAuth";

const validationSchema = yup.object({
  email: yup.string().email().required().default(""),
  password: yup.string().required().default(""),
});

export default function useLoginForm() {
  const auth = useAuth();

  const formik = useFormik({
    initialValues: validationSchema.getDefault(),
    validationSchema: validationSchema,
    async onSubmit(values) {
      try {
        await auth.login(values.email.toLowerCase(), values.password);
      } catch (error) {
        console.error(error);
      }
    },
  });

  return { formik };
}


import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useQuery from "../hooks/useQuery";

export default function GoogleAuth() {
  const query = useQuery();
  const { googleAuth } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const code = query.get("code");

    if (code) {
      googleAuth(code)
        .then(() => history.push("/"))
        .catch((e) => console.log("error" , e));
    }
  }, [query, googleAuth, history]);

  return null;
}

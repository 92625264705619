import clsx from "clsx";

export default function Modal(props) {
  const {
    children,
    containerClassName,
    contentClassName,
    onClose,
    isOpen = false,
  } = props;

  function closeHandler() {
    if (typeof onClose == "function") {
      onClose();
    }
  }

  function stopPropagationHandler(e) {
    e.stopPropagation();
  }

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={clsx("app-modal", containerClassName)}
      onClick={closeHandler}
    >
      <div className={contentClassName} onClick={stopPropagationHandler}>
        {children}
      </div>
    </div>
  );
}

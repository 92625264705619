import React, { Component } from "react";
import Header from "../Component/header";
import Salon from "../Component/salon/salon";
import Footer from "../Component/footer/footer";

class Favorite extends Component {
  render() {
    return (
      <>
        <header className="search white-bg">
          <Header />
        </header>

        <section className="favorites">
          <h2>Favorites</h2>
          <div className="container">
            {Array(3)
              .fill(1)
              .map((k, index) => (
                <Salon title="Book it now!" key={index} favorite />
              ))}
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default Favorite;

import { format } from "date-fns";
import useBoolean from "../hooks/useBoolean";
import Modal from "./Modal";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function PendingReservation(props) {
  const { id, address, startDate, duration, total } = props;
  const {
    value: isModalOpen,
    setFalse: closeModal,
    setTrue: openModal,
  } = useBoolean(false);
  const completeReservationUrl = `${process.env.REACT_APP_API_URL}/reservations/${id}/complete`;
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(startDate)

  const query = new URLSearchParams({
    latitude  : props.latitude ,
    longitude : props.longitude
  }).toString()  
  return (
    <div>
      <div className="wrapper">
        <p>
          <img src="./assets/ic_outline-location-onq.png" alt="" />
          <span>{address}</span>
        </p>
        <p>
          <img src="./assets/ic_baseline-access-times.png" alt="" />
          <span>
            {  date.getHours() + ":" + date.getMinutes()   +  " " + new Date(startDate).toLocaleDateString('fr-FR', options) }
          </span>
        </p>
        <p>
          <img src="./assets/ic_outline-timer.png" alt="" />
          <span>Durée: {duration}min</span>
        </p>
        <h5>Total {total}DH</h5>
      </div>
      <div style={{display : "grid" , gridTemplateColumns : '2fr 2fr' , flexDirection : "column" , justifyContent :"flex-start"}}> 
      <button style={{width : "50%"}} onClick={openModal}>Afficher QR code</button>


<button style={{width : "50%"}} onClick={openModal}> <Link to={`/map/${query}`}>
      Voir Localisation </Link> </button>
      </div>
  

      <Modal
        containerClassName="app-reservation-modal"
        contentClassName="app-reservation-modal-content"
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <div className="qr" >
          <QRCode style={{marginTop : 100}} level="L" size={300} value={completeReservationUrl} />
    
          <h3>Merci D'avoir choisi MeTime</h3>
          <p>
          Nous Vous Remercions De La Confiance Que Vous Accordez Á MeTime
          </p>
          <div>
            <button className="button" onClick={closeModal}>
              Annuler
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

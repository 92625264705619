import React from "react";
import "./services.css";

const service = (props) => {
  const { id, title, price, duration, images, onAddClick } = props;

  function onAddClickHandler() {
    if (typeof onAddClick == "function") {
      onAddClick({ id, title, price, duration });
      document.getElementById("cart").scrollIntoView();
    }
  }

  return (
    <div className="img">
      <img
        src={`${process.env.REACT_APP_API_URL}/services/images/${images[0].filename}`}
        alt={title}
        style={{ marginBottom: 12 }}
      />
      <div className="content" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
        <div className="right c-service" style={{ border: "none" }}>
          <h3>{title}</h3>
          <div className="time-p">
            <span>
              <img src="./assets/ic_baseline-access-time.svg" alt="" />
              {duration}min
            </span>
            <span>
              <img src="./assets/ic_baseline-money.svg" alt="" />
              {price}dh
            </span>
          </div>
        </div>
        <img
          src="./assets/plus.png"
          alt=""
          className="plus"
          onClick={onAddClickHandler}
        />
      </div>
    </div>
  );
};
export default service;

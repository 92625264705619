import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const footer = (props) => {
  return (
    <footer style={{ backgroundColor: "#0f0f0f", padding: 30 }}>
      <div className="container">
        <div>
          <h3>À propos de Metime :</h3>
          <p>
            Avec ses services et ses produits, Metime change le business de la
            beauté marocaine. Notre entreprise est axée sur les consommateurs et
            l'amour de soi, et nous voulons inspirer les individus à exprimer
            leur amour pour eux-mêmes en se connectant avec leur corps. Chacun
            peut s'exprimer à travers la beauté, prendre soin de soi et
            poursuivre sa passion avec Metime.
          </p>
        </div>
        <div>
          <h3>Contactez Nous </h3>
          <a href="mailto:Welcome@metime.ma">
            <img
              style={{ width: 20, height: 20 }}
              src="./assets/white email icon.svg"
              alt="icon"
            />
            <span>Welcome@metime.ma</span>
          </a>
          {/*
          <a href="#">
            <img style={{width : 20 , height :20}}  src="./assets/white address icon.svg" alt="icon" />
            <span>Maroc</span>
          </a> */}
          <a href="tel:0520272289">
            <img
              style={{ width: 20, height: 20 }}
              src="./assets/white phone icon.svg"
              alt="icon"
            />
            <span>0520272289</span>
          </a>
        </div>
        <div>
          <h3>Suivez-Nous Sur</h3>
          <div>
            <a href="https://twitter.com/MeTimeMorocco">
              <img src="./assets/twitter white.svg" alt="icon" />
            </a>
            <a href="https://www.facebook.com/metime.timerc">
              <img src="./assets/fb white.svg" alt="icon" />
            </a>
            <a href="https://www.instagram.com/metime.ma/">
              <img src="./assets/insta white.svg" alt="icon" />
            </a>
          </div>
        </div>
        <div>
          <h3> Liens d'intérêt </h3>
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            <li>
              <Link to="/privacypolicy">Politique De Confidentialité</Link>
            </li>
            <li>
              <Link to="/about">A Propos De Nous</Link>
            </li>
            <li>
              <Link to="/contact">Contacter Nous</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <img
          style={{ width: 160 }}
          src="./assets/white me time logo.svg"
          alt=""
        />
        <span> Tous droits réservés © 2022 MeTime.</span>
      </div>
    </footer>
  );
};

export default footer;

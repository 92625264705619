import React from 'react';
import './download.css';

const download = (props) => {
  return (
    <section id="download" className="app">
        <h2>Téléchargez notre application </h2>
        <h2 className="mobile-title">Get our app</h2>
        <div className="container">
            <div className="get-app">
                <a href="https://play.google.com/store/apps/details?id=com.metimeapp">
                    <span>Download MeTime Sur Android</span>
                    <img src="assets/logos_google-play-icon.png" alt=""/>
                </a>
                <a href="https://apps.apple.com/us/app/metime/id1637551644">
                    <span>Download MeTime Sur Ios</span>
                    <img src="assets/app.png" alt=""/>
                </a>
            </div>

            <img src="assets/Vectary texture.png" alt=""/>
        </div>
    </section>
  );
};
export default download;

import { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Reviews from "../Component/reviews";
import Cart from "../Component/services/cart";
import Service from "../Component/services/service";
import useApi from "../hooks/useApi";
import useQuery from "../hooks/useQuery";
import * as api from "../utils/api";
import Header from "../Component/header";
import { useCart } from "react-use-cart";
import { useParams } from "react-router-dom";
import React from "react";


export default function ServicesPage() {
  const query = useQuery();
  const { id: roomId } = useParams();
  const { addItem, setCartMetadata, emptyCart, metadata } = useCart();
  const fetcher = useCallback(
    async function fetcher() {
      const categories = ["HAMMAM", "TATOUAGE", "COIFFURE", "ESTHETIQUE"];
      let category = query.get("category");

      if (!categories.includes(category)) {
        category = undefined;
      }

      const { data } = await api.getServices(roomId, category);
      api.getServices(roomId, category).then(rsp => console.log(rsp.data))
      return data;
    },
    [query, roomId]
  );

  useEffect(
    function onMount() {
      if ( !metadata || metadata.roomId != roomId) {
        emptyCart();
        setCartMetadata({
          roomId,
        });
      }
    },
    
    // can't add setCartMetaData and emptyCart to deps array, it will cause a infinte renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roomId]
  );

  const { isLoading, data: services /*, error */ } = useApi({ fetcher });

  // TODO: Validate category
  const selectedCategory = query.get("category");

  return (
    <div>
      <header className="search white-bg">
        <Header />
      </header>

      <section className="services">
        <h2>Nos Services</h2>
        <div className="container">
          <div className="navigation">
            <Link
              className={clsx({ selected: selectedCategory === "HAMMAM" })}
              to="?category=HAMMAM"
            >
              Hammam &amp; Spa
            </Link>

            <Link
              className={clsx({ selected: selectedCategory === "TATOUAGE" })}
              to="?category=TATOUAGE"
            >
              Tattoo &amp; Piercing
            </Link>

            <Link
              className={clsx({ selected: selectedCategory === "COIFFURE" })}
              to="?category=COIFFURE"
            >
              COIFFURE
            </Link>

            <Link
              className={clsx({ selected: selectedCategory === "ESTHETIQUE" })}
              to="?category=ESTHETIQUE"
            >
              ESTHETIQUE
            </Link>
          </div>
          <div className="middle">
            {isLoading ? <h1>Loading...</h1> : null}
            {Boolean(!isLoading && services)
              ? services.data.map((service) => (
                  <Service
                    key={service.id}
                    id={service.id}
                    title={service.title}
                    duration={service.duration}
                    price={service.price}
                    images={service.images}
                    onAddClick={addItem}
                  />
                ))
              : null}
          </div>
          <Cart />
        </div>
      </section>


    {/*  <section className="reviews">
        <h2>What our clients say?</h2>
        <Reviews />
              </section> */}
    </div>
  );
}

import React, { useState } from "react";
import Footer from "../Component/footer/footer";
import Header from "../Component/header";
import BookingCity from "../Component/BookingCity";
import BookingMap from "../Component/BookingMap";
import useApi from "../hooks/useApi";
import * as api from "../utils/api";
import "./startBooking.css";

async function fetchCities() {
  const { data } = await api.getCities(true);

  return data
    .filter(function hasRooms(city) {
      return city._count.rooms > 0;
    })
    .sort(function comapreLabel(a, b) {
      const aLabel = a.label.toLocaleLowerCase();
      const bLabel = b.label.toLocaleLowerCase();

      if (aLabel.label > bLabel.label) {
        return 1;
      }

      if (aLabel.label < bLabel.label) {
        return -1;
      }

      return 0;
    });
}

export default function Search() {
  const [displaysearchMap, setDisplaySearchMap] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const { isLoading, data: cities /*, error */ } = useApi({
    fetcher: fetchCities,
  });
  const toggleMap = (e) => {
    e.preventDefault();
    setDisplaySearchMap(!displaysearchMap);
  };

  function loadMoreClickHandler() {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });
  }

  function searchInputChangeHandler(e) {
    setSearchTerm(e.target.value);
  }

  function isSearching() {
    return searchTerm.trim().length != 0;
  }

  let citiesToDisplay;

  if (!isLoading) {
    const startIndex = (pagination.page - 1) * pagination.limit;
    const endIndex = startIndex + pagination.limit;
    const trimmedSearchTerm = searchTerm.trim();
    if (!isSearching()) {
      citiesToDisplay = cities.slice(0, endIndex);
    } else {
      citiesToDisplay = cities
        .filter(function isStartWithSearchTerm(city) {
          return city.label
            .toLocaleLowerCase()
            .trim()
            .startsWith(trimmedSearchTerm);
        })
        .slice(0, 20);
    }
  }

  return (
    <div style={{ margin: 0 }}>
      <Header />
      <section className="booking search-map">
        <h2>Commencez maintenant</h2>
        <p>Commencez maintenant et choisissez le salon de votre choix .</p>
        <div className="booking-form">
          <input
            type="text"
            placeholder="Cherchez votre ville"
            onChange={searchInputChangeHandler}
          />
          <span className="or-divider">Ou</span>
          <button className="booking-form-button" onClick={toggleMap}>
            <span>Cherchez autour de moi</span>
            <img src="./assets/point.png" alt="" />
          </button>
        </div>
        {displaysearchMap ? <BookingMap /> : null}

        {Boolean(!isLoading && citiesToDisplay.length == 0) ? (
          <div className="result">
            <img src="./assets/amico.png" alt="" />
            <h3>
              Ops!!...vous ne trouvez pas ce que vous cherchez.
              <br />
              Vérifier d'autres villes .
            </h3>
          </div>
        ) : null}

        {!displaysearchMap ? (
          <div className="container">
            {isLoading ? <h1>Chargement...</h1> : null}

            {Boolean(!isLoading && citiesToDisplay.length > 0)
              ? citiesToDisplay.map((city) => (
                  <BookingCity
                    key={city.id}
                    id={city.id}
                    label={city.label}
                    roomsCount={city._count.rooms}
                    image = {city.image}
                  />
                ))
              : null}
          </div>
        ) : null}

        {!displaysearchMap && !isSearching() ? (
          <div className="more">
            <button
              className="btn"
              value={searchTerm}
              onClick={loadMoreClickHandler}
            >
              <span>Voir d'autres villes</span>
              <img src="./assets/plus.png" alt="more cities" />
            </button>
          </div>
        ) : null}
      </section>
      <Footer />
    </div>
  );
}

import React from "react";
import { GoogleApiWrapper, Map } from "google-maps-react";

function Map_s(props) {
  return (
    <Map
      google={props.google}
      style={{ width: "85%", height: "500px" ,maxHeight:"500px"}}
      zoom={15}
      initialCenter={{ lat: props.lat, lng: props.lng }}
	  
    ></Map>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(Map_s);

import { useEffect } from 'react';
import Home from './Container/Home';
import Login from './Container/Login';
import Signup from './Container/Signup';
import EmailVerification from './Container/EmailVerification';
import Dashboard from './Container/Dashboard';
import { Route } from 'react-router-dom';
import ResetPassword from './Container/Restpassword';
import Favorite from './Container/Favorite';
import ServicesPage from './Container/ServicesPage';
// import Booking from "./Container/Booking";
import StartBooking from "./Container/StartBooking";
import SearchArea from "./Container/SearchArea";
import ServiceChosen from "./Container/ServiceChosen";
import BookingPage from "./Container/BookingPage";
import ThankyouPage from "./Container/ThankyouPage";
import Reservations from "./Container/Reservations";
import Account from "./Container/Account";
import PrivacyPolicy from "./Container/PrivacyPolicy";
import { useAuth } from "./hooks/useAuth";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CartProvider } from "react-use-cart";
import "react-notifications-component/dist/theme.css";
import "./App.css";
import { ReactNotifications } from "react-notifications-component";
import Support from "./Container/Support";
import About from "./Container/About";
import GoogleAuth from "./Container/GoogleAuth";
import FacebookAuth from "./Container/FacebookAuth";
import Contactus from './Container/Contactus';
import MapCon from './Container/Map';


// import SalonPage from "./Container/SalonPage";
// import ReactNotification from "react-notifications-component";

function App() {
  const { isLoggedIn, isLoading, logout } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(
    function redirectWhenLoggedIn() {
      const publicPages = [
        "/",
        "/login",
        "/signup",
        "/verification",
        "/reset",
        "/privacypolicy",
        "/support",
        "/about",
        "/contact",
        "/auth/google/redirect",
        "/auth/facebook/redirect",
		      ];

      if (!isLoading) {
        if (isLoggedIn) {
          if (pathname === '/login') {
            history.push('/');
          }
        } else {
          logout();
          if (!publicPages.includes(pathname)) {
            history.push('/login');
          }
        }
      }
    },
    [isLoggedIn, isLoading, logout, history, pathname]
  );

  return isLoggedIn ? (
    <CartProvider>
      <ReactNotifications />
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/account" component={Account} />
      <Route exact path="/:id/services" component={ServicesPage} />
      <Route exact path="/booking" component={BookingPage} />
      <Route exact path="/reservations" component={Reservations} />
      <Route exact path="/startbooking" component={StartBooking} />
      <Route exact path="/cities/:id/salons" component={SearchArea} />
      <Route exact path="/support" component={Support} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contactus} />
      <Route exact path="/privacypolicy" component={PrivacyPolicy} />
      <Route exact path="/map/" component={MapCon} />

      <Route exact path="/map/:location" component={MapCon} />


      
    </CartProvider>
  ) : (
    <>
      <ReactNotifications />
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/verification" component={EmailVerification} />
      <Route exact path="/reset" component={ResetPassword} />
      <Route exact path="/servicechosen" component={ServiceChosen} />
      <Route exact path="/thankyoupage" component={ThankyouPage} />
      <Route exact path="/favorite" component={Favorite} />
      <Route exact path="/privacypolicy" component={PrivacyPolicy} />
      <Route exact path="/support" component={Support} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contactus} />
      <Route exact path="/auth/google/redirect" component={GoogleAuth} />
      <Route exact path="/auth/facebook/redirect" component={FacebookAuth} />
    </>
  );
}

export default App;

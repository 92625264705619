import useEmailStepForm from '../hooks/useEmailStepForm';
import usePasswordStepForm from '../hooks/usePasswordStepForm';
import useTokenStepForm from '../hooks/useTokenStepForm';
import './steps.css';

export function EmailStep(props) {
  const { onSuccess } = props;
  const { formik } = useEmailStepForm({
    onSuccess,
  });

  return (
    <div className="verification v-reset">
      <h1>Reset your password</h1>
      <p>Enter your email so we can help you get back to your account</p>
      <form className="hero-form" onSubmit={formik.handleSubmit}>
        <div>
          <input
            type="text"
            name="email"
            placeholder="Email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </div>
        {Boolean(formik.touched.email && formik.errors.email) ? (
          <label>{formik.errors.email}</label>
        ) : null}
        <input
          type="submit"
          style={{ color: 'black' }}
          value="Reset my password"
        />
      </form>
    </div>
  );
}

export function TokenStep(props) {
  const { email, onSuccess } = props;
  const { formik, resendResetPassword } = useTokenStepForm({
    initialValues: {
      email,
    },
    onSuccess,
  });

  return (
    <div className="verification">
      <h1 className="form-title">Email verification</h1>
      <p className="form-sub-title">Enter the 6 digits we sent to your email</p>
      <form className="hero-form" onSubmit={formik.handleSubmit}>
        <div>
          <input
            type="text"
            name="token"
            placeholder="Verification code"
            value={formik.values.token}
            onChange={formik.handleChange}
            autoFocus
          />
        </div>
        <input type="submit" value="Verify my email" />
        <p>
          Didn't receive the email?
          <button className="forgot-resend" onClick={resendResetPassword}>
            Resend
          </button>
        </p>
      </form>
    </div>
  );
}

export function PasswordStep(props) {
  const { email, token, onSuccess } = props;
  const { formik } = usePasswordStepForm({
    initialValues: { email, token },
    onSuccess,
  });

  return (
    <div className="new_password">
      <h1 className="form-title">New password</h1>
      <p className="form-sub-title">Enter your new password</p>
      <form className="hero-form" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <input
            type="password"
            name="newPassword"
            placeholder="Type your password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            autoFocus
          />
          <img src="./assets/eye.png" alt="toggle password" />
        </div>
        {Boolean(formik.touched.newPassword && formik.errors.newPassword) ? (
          <label>{formik.errors.newPassword}</label>
        ) : null}
        <div className="form-group">
          <input
            type="password"
            name="confirmPassword"
            placeholder="Repeat your password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
          />
          <img src="./assets/eye.png" alt="toggle password" />
        </div>
        {Boolean(
          formik.touched.confirmPassword && formik.errors.confirmPassword
        ) ? (
          <label>{formik.errors.confirmPassword}</label>
        ) : null}
        <input type="submit" value="Confirm my new password" />
      </form>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./testimonials.css";

const Testmonial = (props) => {
  let TestimonialBtns = null;
  let TestimonialSlides = null;
  let TestimonialDivs = null;
  const images = {
    0: "/assets/chanel.png",

    3: "/assets/ysl.png",
    1: "/assets/chanel.png",
    2: "/assets/Givenchy.png",
    4: "/assets/chanel.png",
  };
  let testimonial = 1;

  useEffect(() => {
    TestimonialBtns = document.querySelectorAll(
      ".testimonials .content #testimonial-btns button"
    );
    TestimonialSlides = document.querySelector(
      ".testimonials .content .content-slides"
    );
    TestimonialDivs = document.querySelectorAll(
      ".testimonials .content-slides div"
    );

    document.getElementById("brand").src = images[testimonial];
    document.getElementById("brandmob").src = images[testimonial];
  }, [props]);

  function testimonialSlide() {
    //reset the transition to the default
    TestimonialSlides.style.transition = "transform 0.5s";
    TestimonialSlides.style.transform = `translateX(calc(-${testimonial} * 100%))`;
    document.getElementById("brand").src = images[testimonial];
    document.getElementById("brandmob").src = images[testimonial];
  }

  // click event function

  function testimonialBtnClick(e) {
    if (e.target.id === "left") {
      testimonial--;
    } else {
      testimonial++;
    }

    e.target.disabled = true;
    testimonialSlide();
  }

  function handleTransition() {
    if (testimonial === -1) {
      TestimonialSlides.style.transition = "none";
      testimonial = 4;
      TestimonialSlides.style.transform = `translateX(calc(-${testimonial} * 100%))`;
    }
    if (testimonial === 4) {
      TestimonialSlides.style.transition = "none";
      testimonial = 0;
      TestimonialSlides.style.transform = `translateX(calc(-${testimonial} * 100%))`;
    }
    TestimonialBtns[0].disabled = false;
    TestimonialBtns[1].disabled = false;
  }
  return (
    <div className="container">
      <div className="content">
        <img
          src="assets/ic_baseline-format-quote.png"
          className="quote"
          alt=""
        />
        <div className="content-slides" onTransitionEnd={handleTransition}>
          <div>
            <p>
              « Une femme qui se coupe les cheveux est une femme qui s’apprête à
              changer de vie » – Coco Chanel
            </p>
          </div>
          <div>
            <p>
              {" "}
              « La plus belle couleur au monde est celle qui vous va bien » -
              Coco Chanel
            </p>
          </div>
          <div>
            <p>
              {" "}
              « La coiffure est l’ultime indice pour savoir si une femme se
              connait vraiment » – Givenchy
            </p>
          </div>
          <div>
            <p>
              {" "}
              « Sans élégance de coeur, il n'y a pas d'élégance.» - Yves Saint
              Laurent
            </p>
          </div>
        </div>
        <div id="testimonial-btns">
          <button onClick={testimonialBtnClick} id="left">
            <img
              style={{ width: 69, height: 69 }}
              src="assets/flesh on left.svg"
              alt="left"
            />
          </button>
          <button onClick={testimonialBtnClick} id="right" className="right">
            <img
              style={{ width: 69, height: 69 }}
              src="assets/flesh on right.svg"
              alt="right"
            />
          </button>
        </div>
      </div>
      <div className="img">
        <img
          style={{ width: 400, height: 400, marginRight: 40 }}
          id="brand"
          alt=""
        />
        <img
          style={{ width: 150, height: 150 }}
          id="brandmob"
          className="testimonials-mobile-img"
          alt=""
        />
      </div>
    </div>
  );
};

export default Testmonial;
